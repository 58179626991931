import { writable } from "svelte/store";

const loadedMeetups = writable([])

const customMeetupStore = {
  subscribe: loadedMeetups.subscribe, // not calling it subscribe() but just pointing it
  setMeetups: (meetupArray) => {
    loadedMeetups.set(meetupArray);
  },
  addMeetup: (meetupData) => { // these are properties, I turn them into methods by assigning functions as values for these properties
    const newMeetup = {
      ...meetupData
    }
    loadedMeetups.update(items => {
      return [newMeetup, ...items]
    })
  },
  toggleFavorite: id => {
    loadedMeetups.update(items => {
      const updatedMeetup = { ...items.find((m) => m.id === id) };
      updatedMeetup.isFavorite = !updatedMeetup.isFavorite;
      const meetupIndex = items.findIndex((m) => m.id === id);
      const updatedMeetups = [...items];
      updatedMeetups[meetupIndex] = updatedMeetup;
      return updatedMeetups;
    })
  }
}

export default customMeetupStore
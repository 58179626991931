export function isEmpty(val) {
    return val.trim().length === 0;
}

export function isValidEmail(val) {
    return new RegExp(
      "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
    ).test(val);
  }

  export function hasWhiteSpace(val) {
    return val.indexOf(' ') >= 0;
  }
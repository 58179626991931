<script>
  import loadedMeetups from "./meetups-store";
  import TextInput from "../UI/TextInput.svelte";
  import Button from "../UI/Button.svelte";
  import Modal from "../UI/Modal.svelte";
  import { isEmpty, hasWhiteSpace } from "../helpers/validation.js";
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  let title = "";
  let dates = "";
  let location = "";
  let gallery = "";
  let description = "";
  let imageUrl = "";

  $: titleValid = !isEmpty(title);
  $: datesValid = !isEmpty(dates);
  $: locationValid = !isEmpty(location);
  $: descriptionValid = !isEmpty(description);
  $: imageUrlValid = !hasWhiteSpace(imageUrl) && !isEmpty(imageUrl);
  $: galleryValid = !isEmpty(gallery);
  $: formIsValid =
    titleValid &&
    datesValid &&
    locationValid &&
    descriptionValid &&
    imageUrlValid &&
    galleryValid;

  function submitForm() {
    const meetupData = {
      title: title,
      dates: dates,
      description: description,
      imageUrl: imageUrl,
      gallery: gallery,
      location: location,
      past: false,
    };

    // meetups.push(newMeetup); // DOES NOT WORK!
    loadedMeetups.addMeetup(meetupData);
    fetch(
      "https://crewnew-meetup-default-rtdb.europe-west1.firebasedatabase.app/meetups.json",
      {
        method: "POST",
        body: JSON.stringify({ ...meetupData, isFavorite: false }),
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error("An error occurred, please try again!");
        }
        return res.json();
      })
      .catch((err) => {
        console.log(err);
      });

    dispatch("save");
  }

  function cancelSubmit() {
    dispatch("cancel");
  }
</script>

<svelte:head>
  <title>Add/Edit Meetup | CrewNew Meetups</title>
</svelte:head>

<Modal title="Add a new exciting MeetUp" on:cancel>
  <form on:submit|preventDefault={submitForm}>
    <TextInput
      id="title"
      placeholder = "eg. Winter Canarias'21"
      valid={titleValid}
      validityMessage="Please enter a valid title."
      required="true"
      value={title}
      on:input={(event) => (title = event.target.value)}
    />
    <TextInput
      id="dates"
      placeholder = "eg. 6th Mar - 29th Mar"
      valid={datesValid}
      validityMessage="Dates can not be empty."
      required="true"
      value={dates}
      on:input={(event) => (dates = event.target.value)}
    />
    <TextInput
      id="location"
      placeholder = "eg. Maspalomas and Las Palmas"
      valid={locationValid}
      validityMessage="Please enter a valid location."
      required="true"
      value={location}
      on:input={(event) => (location = event.target.value)}
    />
    <TextInput
      id="imageUrl"
      placeholder = "https://"
      valid={imageUrlValid}
      validityMessage="Can't be empty and don't use space! Comma separate keywords."
      label="Main image URL"
      required="true"
      value={imageUrl}
      on:input={(event) => (imageUrl = event.target.value)}
    />
    <TextInput
      id="gallery"
      placeholder = "https//,https://"
      valid={galleryValid}
      validityMessage="This is not a valid gallery"
      required = true
      value={gallery}
      on:input={(event) => (gallery = event.target.value)}
    />
    <TextInput
      id="description"
      placeholder = "Sjuuper cool, cool, cool...."
      valid={descriptionValid}
      validityMessage="Please enter a valid description."
      required = true
      controlType="textarea"
      value={description}
      on:input={(event) => (description = event.target.value)}
    />
  </form>
  <div slot="footer">
    <Button on:click={submitForm} disabled={!formIsValid}>Save</Button>
  </div>
</Modal>

<style>
  form {
    width: 100%;
  }
</style>

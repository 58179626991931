<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  import loadedMeetups from "./meetups-store";
  import Badge from "../UI/Badge.svelte";
  import Button from "../UI/Button.svelte";

  export let id;
  export let title;
  export let dates;
  export let imageUrl;
  export let description;
  export let location;
  export let isFav;
  export let past;
  export let role;

  let shortDescription = description.split(' ').slice(0,13).join(' ') + "..."; // first 13 words

  function toggleFavorite() {
    loadedMeetups.toggleFavorite(id);
  }
</script>

<article>
  <header>
    <h1>
      {title}
      {#if isFav}
        <Badge>ATTENDING</Badge>
      {/if}
    </h1>
    <h2>{dates}</h2>
    <p>{location}</p>
  </header>
  <div class="image">
    <img src="https://cms.crewnew.com/assets/{imageUrl}" alt={title} />
  </div>
  <div class="content">
    <p>{shortDescription}</p>
  </div>
  <footer>
    {#if !past && role === "admin"}
      <Button
        mode="outline"
        type="button"
        color={isFav ? null : "success"}
        on:click={toggleFavorite}
      >
        {isFav ? "Can't attend" : "Attend!"}</Button
      >
    {/if}
    {#if !past && role != "admin"}
      <Button
        mode="outline"
        type="button"
        on:click={() => dispatch("visitorattend", id)}
      >
        Attend</Button
      >
    {/if}
    {#if past}
      <Button on:click={() => dispatch("showdetails", [id, title])}>Show Gallery</Button>
    {/if}
  </footer>
</article>

<style>
  article {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 5px;
    background: white;
    margin: 1rem;
  }

  header,
  .content,
  footer {
    padding: 1rem;
  }

  .image {
    width: 100%;
    height: 14rem;
  }

  .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  h1 {
    font-size: 1.25rem;
    margin: 0.5rem 0;
    font-family: "Roboto Slab", sans-serif;
  }

  h2 {
    font-size: 1rem;
    color: #808080;
    margin: 0.5rem 0;
  }

  p {
    font-size: 1.25rem;
    margin: 0;
  }

  div {
    text-align: right;
  }
  .content {
    height: 4rem;
  }
</style>

<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  import Modal from "../UI/Modal.svelte";
  import TextInput from "../UI/TextInput.svelte";
  import Button from "../UI/Button.svelte";

  let email = null;
  let password = null;
  let state = "start";
  let errorMessage = "";
  let body = "";

  function loginForm() {
    body = '{"email": "' + email + '"}';
    fetch("https://apps.crewnew.com/api/rest/logn/nn", {
      method: "POST",
      body: body,
      headers: { "Content-type": "application/json; charset=UTF-8" },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("An error occurred, please try again!");
        }
        return res.json();
      })
      .then((data) => {
        if (password === data.users[0].password) {
          state = "logged";
          errorMessage = "Logged in!";
        }
      })
      .catch((err) => {
        console.log(err);
      });
      
    state = "wrong";
    errorMessage = "Wrong email and/or password!";
    setTimeout(endError, 2500);
    F;
  }
  function endError() {
    errorMessage = "";
  }
</script>

<Modal title="Login" on:cancel>
  {#if state === "wrong"}
    <p class="error">{errorMessage}</p>
  {/if}
  <form on:submit|preventDefault={loginForm}>
    <TextInput
      id="email"
      value={email}
      on:input={(event) => (email = event.target.value)}
    />
    <TextInput
      id="password"
      value={password}
      type="password"
      on:input={(event) => (password = event.target.value)}
    />
  </form>
  <div slot="footer">
    <Button on:click={loginForm}>Login</Button>
  </div></Modal
>

<style>
  .error {
    color: rgb(108, 27, 27);
    font-weight: bolder;
  }
</style>

export function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie); //Decode the cookie string, to handle cookies with special characters, e.g. '$'
    let ca = decodedCookie.split(';'); //Split document.cookie on semicolons into an array
    for (let i = 0; i < ca.length; i++) { //read out each value c = ca[i])
        let c = ca[i];
        //If the cookie is found (c.indexOf(name) == 0), return the value of the cookie (c.substring(name.length, c.length).
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return ""; //If the cookie is not found, return ""
}
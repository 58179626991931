<script>
  document.body.scrollIntoView();
  import { onDestroy, createEventDispatcher } from "svelte";
  import loadedMeetups from "./meetups-store.js";
  import Button from "../UI/Button.svelte";
  import Modal from "../UI/Modal.svelte";

  export let id;
  let showImage = false;
  let currentPic;
  let selectedMeetup = [];
  let galleryImages = [];

  let tempImages = [];
  fetch("https://apps.crewnew.com/api/rest/cms/contents/" + id, {
    method: "POST",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "x-hasura-admin-secret": "HxXUX27D9gTNBSSW",
    },
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error("An error occurred, please try again!");
      }
      return res.json();
    })
    .then((data) => {
      tempImages = data.cms_page_contents;
      const mainImageTemp = {
        name: "Main",
        image: selectedMeetup.image_main,
        cms_pages_cms_page_contents: [
          {
            cms_page: {
              image_main: "73300e2c-b6b2-4906-92aa-45f6b479205f",
            },
          },
        ],
      };
      tempImages.unshift(mainImageTemp);
    })
    .catch((err) => {
      console.log(err);
    });

  const unsubscribe = loadedMeetups.subscribe((items) => {
    selectedMeetup = items.find((i) => i.id === id);
  });

  const dispatch = createEventDispatcher();

  onDestroy(() => {
    unsubscribe();
  });
  galleryImages = selectedMeetup.cms_pages_cms_page_contents;
  //$: galleryImages = selectedMeetup.cms_pages_cms_page_contents;

  //console.log(galleryImages);

  function showModal(i) {
    showImage = !showImage;
    currentPic = i.i;
  }

  function cancelModal() {
    showImage = !showImage;
  }

  function previousPic() {
    if (currentPic === 0) currentPic = galleryImages.length - 1;
    else currentPic--;
  }

  function nextPic() {
    if (currentPic === galleryImages.length - 1) currentPic = 0;
    else currentPic++;
  }
</script>

<svelte:head>
  <title>{selectedMeetup.title} | CrewNew Meetups</title>
</svelte:head>

<section>
  <div class="content">
    <h1>{selectedMeetup.menu_title}</h1>
    <h2>{selectedMeetup.title} - {selectedMeetup.title2}</h2>
    <p>{selectedMeetup.description}</p>
    <p>
      <Button mode="outline" on:click={() => dispatch("close")}>Back</Button>
    </p>
    {#if showImage}
      <Modal on:cancel={cancelModal}
        ><img
          src="https://cms.crewnew.com/assets/{tempImages[currentPic].image}"
          alt={selectedMeetup.title}
        />
        <Button mode="outline" on:click={previousPic}>&#60;&#60; prev</Button
        >&nbsp;&nbsp;&nbsp;&nbsp;
        <Button mode="outline" on:click={nextPic}>next &#62;&#62;</Button
        ></Modal
      >
    {/if}
    <ul class="img-list">
      {#each tempImages as picture, i}
        <li on:click={() => showModal({ i })}>
          <img
            src="https://cms.crewnew.com/assets/{picture.image}"
            alt={selectedMeetup.title}
          />
          <!-- {picture.cms_page_content.image} -->
        </li>
      {/each}
    </ul>
  </div>
  <div class="footer">
    <p>
      <Button mode="outline" on:click={() => dispatch("close")}>Back</Button>
    </p>
  </div>
</section>

<style>
  .footer {
    clear: both;
    position: relative;
    text-align: center;
    margin: auto;
  }
  section {
    padding: 2.5%;
  }
  .img-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .img-list li {
    border: 5px solid transparent;
    box-sizing: border-box;
    width: 33.33%;
    height: 14em;
    float: left;
    position: relative;
    cursor: pointer;
  }
  .img-list img {
    max-width: 100%;
    vertical-align: middle;
  }

  /* The hover effect
-------------------------------------------------------------- */
  .img-list li:before {
    transition: all 0.5s ease;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #42375b;
    transform: scale(0);
  }
  .img-list li:hover:before {
    opacity: 0.5;
    transform: scale(1);
  }
  .img-list li:after {
    transition: all 0.6s ease 0.2s;
    content: "";
    position: absolute;
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: 8px;
    border: 1px solid #aaa;
    background: #000;
    opacity: 0;
    transform: scale(0);
  }
  .img-list li:hover:after {
    opacity: 0.35;
    transform: scale(1);
  }
  section {
    margin-top: 4rem;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .content {
    text-align: center;
    width: 80%;
    margin: auto;
  }

  h1 {
    font-size: 2rem;
    font-family: "Roboto Slab", sans-serif;
    margin: 0;
  }

  h2 {
    font-size: 1.25rem;
    color: #6b6b6b;
  }

  p {
    font-size: 1.5rem;
  }
</style>

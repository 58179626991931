<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let controlType = null;
  export let id;
  export let label = null;
  export let rows = null;
  export let value;
  export let type = "text";
  export let valid = true;
  export let validityMessage = "This field can't be empty";
  export let required = null;
  export let placeholder = null;

  let touched = false;

  if (touched) dispatch('touched')

  if (!label) {
    // if "label" not passed then capitalise the "id"
    label = id.charAt(0).toUpperCase() + id.slice(1);
  }
</script>

<div class="form-control">
  <label for={id}>{label} {#if required}<span class="required">*</span>{/if}</label>
  {#if controlType === "textarea"}
    <textarea
      {rows}
      {id}
      {value}
      class:invalid={!valid && touched}
      on:input
      on:blur={() => (touched = true)}
    />
  {:else}
    <input
      {type}
      {id}
      {value}
      placeholder = {placeholder}
      class:invalid={!valid && touched}
      on:input
      on:blur={() => (touched = true)}
    />
  {/if}
  {#if validityMessage && !valid && touched}
    <p class="error-message">{validityMessage}</p>
    <audio autoplay>
      <source src="error.mp3" type="audio/mpeg">
    </audio>
  {/if}
</div>

<style>
  .required {
    color:rgb(154, 31, 31);
    font-weight: 500;
    font-size: larger;
  }
  
  input,
  textarea {
    display: block;
    width: 100%;
    font: inherit;
    border: none;
    border-bottom: 2px solid #ccc;
    border-radius: 3px 3px 0 0;
    background: white;
    padding: 0.15rem 0.25rem;
    transition: border-color 0.1s ease-out;
  }

  input:focus,
  textarea:focus {
    border-color: #42375b;
    outline: none;
  }

  label {
    display: block;
    margin-bottom: 0.5rem;
    width: 100%;
  }

  .form-control {
    padding: 0.5rem 0;
    width: 100%;
    margin: 0.25rem 0;
  }

  .invalid {
    border-color: rgb(188, 0, 0);
    color: rgb(255, 193, 193);
  }

  .error-message {
    color: rgb(188, 0, 0);
    margin: 0.25rem 0;
  }
</style>

<script>
    import Modal from "./Modal.svelte";
</script>
<Modal
    title="CrewNew Team & IT Prof. / Developers' Meetups"
    on:cancel
  >
    <p>
      CrewNew full-timers, freelancers and our developers friends and people from the meetup location who possibly want to join us meet ~3 times per year in various locations. Mostly in various Eastern-Europe locations. In
      the summer we meet up in Estonia that is the #1 country in unicorns per capita. Famous e-country. In the winter we meet in Canary Islands that is the only location in
      EU where you can enjoy hot summer weather in the middle of the winter! Cluj Napoca in Romania and Sarajevo in Bosnia are one of the most frequent locations due to our offices in those locations.
    </p>
    <p><strong>We're free to meet new guys, speak, share and why not to co-work in the future! Ideal opportunity to jump on-board without going through the tedious recruitment process is to join us in one of the meetups!</strong></p>
    <p>
      Everybody who have attended at least once know how much fun the meetups
      are. They are not comparable to some huge companies' summer days. We're
      hanging around usually 1,5-4 weeks, have fun together, discuss how to
      improve the development process, discuss new tecnologies, exchange experiences, prepare presentations/talks, write code,
      learn together, chill in cool locations, code more, learn more, socialise more...
    </p>
  </Modal>
<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  import Modal from "../UI/Modal.svelte";
  import TextInput from "../UI/TextInput.svelte";
  import Button from "../UI/Button.svelte";
  import {
    isEmpty,
    isValidEmail,
    hasWhiteSpace,
  } from "../helpers/validation.js";
  import { setCookie } from "../helpers/cookies.js";
  import SelectMessenger from "../UI/SelectMessenger.svelte";

  let email = "";
  let firstname = "";
  let lastname = "";
  let linkedin = "";
  let mobile = "";
  let whatsapp = false;
  let wa_or_mobile = "mobile";
  let instantMessengerValue = "";
  let instantMessengerType = "";
  let content = "email";
  let body = "";
  let from_where;
  let manager_id = 20;
  let project_id = 233;
  export let id = null;
  export let firstMessage = null;
  export let step1Message = null;
  export let urlData = null;

  $: emailValid = isValidEmail(email);
  $: firstnameValid = !isEmpty(firstname);
  $: lastnameValid = !isEmpty(lastname);
  $: linkedinValid = !hasWhiteSpace(linkedin) && !isEmpty(linkedin);
  $: mobileValid = !isEmpty(mobile);
  $: messengerValid = !isEmpty(instantMessengerValue);
  $: formIsValid =
    firstnameValid &&
    lastnameValid &&
    linkedinValid &&
    mobileValid &&
    messengerValid;

  function submitEmail() {
    if (email.includes("@crewnew.com")) {
      body =
        '{ "email": "' +
        email +
        '", "message": "Event ID: ' +
        id +
        '", "type": 3}';
      submitForm(body);
    } else {
      content = "more";
    }
  }

  function submitMore(data) {
    if (whatsapp) wa_or_mobile = "whatsapp";
    if (id) {
      // attending meetup
      from_where = id;
    } else {
      // applying for a job
      if (urlData) {
        from_where = urlData.split("-")[2];
        manager_id = urlData.split("-")[1];
        project_id = urlData.split("-")[0];
      }
    }

    body =
      '{ "email": "' +
      email +
      '", "from_where": "' +
      from_where +
      '", "name": "' +
      firstname +
      " " +
      lastname +
      '", "manager_id": ' +
      manager_id +
      ', "project_id": ' +
      project_id +
      ', "site": "LinkedIn", "url": "' +
      linkedin +
      '", "data": [{"type": "' +
      wa_or_mobile +
      '", "contact": "' +
      mobile +
      '"}, {"type": "' +
      instantMessengerType +
      '", "contact": "' +
      instantMessengerValue +
      '"}]}';
    submitForm(body);
  }

  function submitForm(data) {
    fetch("https://apps.crewnew.com/api/rest/apply/step1", {
      method: "POST",
      body: data,
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "x-hasura-admin-secret": "HxXUX27D9gTNBSSW",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("An error occurred, please try again!");
        }
        return res.json();
      })
      .then((data) => {
        let response = Object.values(data);
        let cookie = [
          response[0].id,
          firstname,
          response[0].confirmation_token,
        ];
        setCookie("cn_u", cookie, 999);
        if (id) {
          window.location.href = "#/success";
        } else {
          window.location.href = "#/step2";
          dispatch("step2");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
</script>

<svelte:head>
  <title>🚀 1st Step to your Cool Dev Life -> CrewNew</title>
</svelte:head>

{#if content === "email"}
  <Modal title="Great choice! Speak to you soon🚀" on:cancel>
    <form on:submit|preventDefault={submitEmail}>
      <p>
        {firstMessage}
      </p>
      <TextInput
        id="email"
        valid={emailValid}
        validityMessage="Please enter a email address."
        required="true"
        value={email}
        on:input={(event) => (email = event.target.value)}
      />
    </form>
    <div slot="footer">
      <Button on:click={submitEmail} disabled={!emailValid}>I'm in</Button>
    </div>
  </Modal>
{:else if content === "more"}
  <Modal title={step1Message} on:cancel>
    <TextInput
      id="firstname"
      valid={firstnameValid}
      validityMessage="Please enter your given name."
      required="true"
      value={firstname}
      on:input={(event) => (firstname = event.target.value)}
    />
    <TextInput
      id="lastname"
      valid={lastnameValid}
      validityMessage="Please enter your legal surname."
      required="true"
      value={lastname}
      on:input={(event) => (lastname = event.target.value)}
    />
    <TextInput
      id="linkedin"
      placeholder="https://"
      valid={linkedinValid}
      validityMessage="Please enter a valid LinkedIn URL starting with https://"
      required="true"
      value={linkedin}
      on:input={(event) => (linkedin = event.target.value)}
    />
    <TextInput
      id="mobile"
      placeholder="+00"
      valid={mobileValid}
      validityMessage="Please enter a valid mobile number you can receive a confirmation SMS."
      label="Mobile (if WhatsApp then check the box below)"
      required="true"
      value={mobile}
      on:input={(event) => (mobile = event.target.value)}
    />
    <span class="type"
      ><input type="checkbox" name="whatsapp" bind:checked={whatsapp} /> WhatsApp
    </span>
    <TextInput
      id="instantMessengerValue"
      label="Instant Messenger (pick also below the messenger type)"
      validityMessage="Please enter a valid instant messenger ID that we can use to chat with you."
      valid={messengerValid}
      required="true"
      value={instantMessengerValue}
      on:input={(event) => (instantMessengerValue = event.target.value)}
    />
    <div class="selectLabel">
      Messenger type: <span class="required">*</span>
    </div>
    <SelectMessenger
      on:messenger={(event) => (instantMessengerType = event.detail.value)}
    />
    <div slot="footer">
      <Button on:click={submitMore} disabled={!formIsValid}>Let's Fly</Button>
    </div>
  </Modal>
{/if}

<style>
  .type {
    color: rgb(123, 123, 123);
    font-size: x-small;
    margin-bottom: 10px;
  }
  .selectLabel {
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .required {
    color: rgb(99, 18, 18);
    font-weight: 500;
    font-size: larger;
  }
</style>

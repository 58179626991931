<header>
  <h1>CrewNew MeetUp</h1>
</header>

<style>
  header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 4rem;
    background: #42375b;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
    z-index:99;
  }

  h1 {
    color: white;
    font-family: "Roboto Slab", serif;
    margin: 0;
  }
</style>

<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  import Modal from "../UI/Modal.svelte";
  import Button from "../UI/Button.svelte";
  import TextInput from "../UI/TextInput.svelte";
  import { getCookie } from "../helpers/cookies.js";
  let cookie = getCookie("cn_u").split(",");

  let hrly_small = null;
  let hrly_big = null;
  let hrly_halftime = null;
  let hrly_fulltime = null;
  let github = null;
  let code = null;
  let password = "";
  let password2 = "";

  function submitForm(data) {
    data = '{ "input": { "id":' 
    + cookie[0] + ', "password": "' 
    + password + '", "name": "f", "webs": { "data": [ { "site": "GitHub", "url": "' 
    + github + '" } ], "on_conflict": { "constraint": "webs_pkey", "update_columns": ["site","url"] } }, "rates": { "on_conflict": { "constraint": "rates_pkey", "update_columns": ["hourly_rate","minimum_hour"] },  "data": [ { "hourly_rate": ' 
    + hrly_small + ', "minimum_hour": 0 }, { "hourly_rate": ' 
    + hrly_big + ', "minimum_hour": 50 } ] } } }';

    fetch("https://apps.crewnew.com/api/rest/apply/step3", {
      method: "POST",
      body: data,
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "x-hasura-admin-secret": "Yd3L3tExefP5AUyP",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("An error occurred, please try again!");
        }
        return res.json();
      })
      .then((data) => {
        window.location.href = "#/success";
        dispatch("cancel");
      })
      .catch((err) => {
        console.log(err);
      });
  }
</script>

<svelte:head>
  <title>🙌 final step | Join CrewNew</title>
</svelte:head>

<Modal title="Grat job🙌 Final step!" wide="true" on:cancel closable="no">
  <p>
    We're almost done so let's just fill few more questions and let's create
    your account 📋
  </p>
  <section id="apply">
    <article>
      <TextInput
        id="github"
        required="true"
        placeholder="https://"
        label="GitHub URL"
        value={github}
        on:input={(event) => (github = event.target.value)}
      />
    </article>
    <article>
      <TextInput
        id="code"
        required="true"
        placeholder="eg. check 'x-startup' where I have implemented advanced..."
        label="Which repo do you suggest to check? What's worth to see there?"
        value={code}
        on:input={(event) => (code = event.target.value)}
      />
    </article>
    <article>
      <TextInput
        id="hrly_big"
        required="true"
        label="Hourly rate in EUR if there's maybe at least 200h of work? Long-term bigger project."
        placeholder="eg. 15"
        value={hrly_big}
        on:input={(event) => (hrly_big = event.target.value)}
      />
    </article>
    <article>
      <TextInput
        id="hrly_small"
        required="true"
        placeholder="eg. 18"
        label="What would be your desired hourly rate € if there's maybe less than 20h of work? Or maybe just 1-2h? Small freelancing project"
        value={hrly_small}
        on:input={(event) => (hrly_small = event.target.value)}
      />
    </article>
    <article>
      <TextInput
        id="hrly_halftime"
        required="true"
        placeholder="eg. 1000"
        label="Monthly salary expectation if long-term and half-time (~80h per month)"
        value={hrly_halftime}
        on:input={(event) => (hrly_halftime = event.target.value)}
      />
    </article>
    <article>
      <TextInput
        id="hrly_fulltime"
        required="true"
        placeholder="eg. 2000"
        label="Monthly salary expectation if long-term and full-time (~160h per month)"
        value={hrly_fulltime}
        on:input={(event) => (hrly_fulltime = event.target.value)}
      />
    </article>
    <article>
      <TextInput
        id="password"
        required="true"
        type="password"
        label="Pick password to access CrewNew"
        value={password}
        on:input={(event) => (password = event.target.value)}
      />
    </article>
    <article>
      <TextInput
        id="password2"
        required="true"
        type="password"
        label="Repeat password"
        value={password2}
        on:input={(event) => (password2 = event.target.value)}
      />
    </article>
  </section>
  <Button on:click={submitForm}>Finish</Button>
</Modal>

<style>
  article {
    background: white;
    margin: 1rem;
  }
  section {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }

  @media (min-width: 768px) {
    section {
      grid-template-columns: repeat(2, 1fr);
    }
  }
</style>

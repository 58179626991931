<script>
  import MeetupItem from "./MeetupItem.svelte";
  import { scale } from "svelte/transition";
  import { flip } from "svelte/animate";

  export let meetups;
  export let role
</script>

<svelte:head>
  <title>CrewNew MeetUp - Eastern Europe Programmers Meetups</title>
</svelte:head>

<section id="meetups">
  {#each meetups as meetup (meetup.id)}
    <div in:scale animate:flip={{duration: 250}}>
      <MeetupItem
        id={meetup.id}
        title={meetup.menu_title}
        dates={meetup.title2}
        description={meetup.description}
        imageUrl={meetup.image_main}
        location={meetup.title}
        isFav={meetup.isFavorite}
        past={meetup.date}
        on:showdetails
        on:visitorattend
        {role}
      />
    </div>
  {/each}
</section>

<style>
  section {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }

  @media (min-width: 768px) {
    section {
      grid-template-columns: repeat(2, 1fr);
    }
  }
</style>

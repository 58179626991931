<script>
  import loadedMeetups from "./Meetups/meetups-store";
  import Header from "./UI/Header.svelte";
  import MeetupGrid from "./Meetups/MeetupGrid.svelte";
  import EditMeetup from "./Meetups/EditMeetup.svelte";
  import Step1 from "./Apply/Step1.svelte";
  import Button from "./UI/Button.svelte";
  import MeetupDetail from "./Meetups/MeetupDetail.svelte";
  import LoadingSpinner from "./UI/LoadingSpinner.svelte";
  import Error from "./UI/Error.svelte";
  import InfoText from "./UI/InfoText.svelte";
  import Login from "./Auth/Login.svelte";
  import url from "./url";
  import { setCookie, getCookie } from "./helpers/cookies.js";
  import Step2 from "./Apply/Step2.svelte";
  import Step3 from "./Apply/Step3.svelte";
  import Modal from "./UI/Modal.svelte";

  let editMode;
  let page = "overview";
  let pageData = {};
  let isLoading = true;
  let error;
  let role = "visitor";
  let login = null;
  let urlData = null;
  let beenHere = false;
  let cookie = getCookie("cn_u").split(",");

  if (window.location.href.split("#")[1] === "/apply") {
    editMode = "apply";
    urlData = window.location.href.split("#")[2];
    beenHere = true;
  }

  fetch("https://apps.crewnew.com/api/rest/meetups", {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "x-hasura-admin-secret": "HxXUX27D9gTNBSSW",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("An error occurred, please try again!");
        }
        return res.json();
      })
      .then((data) => {
        isLoading = false;
        loadedMeetups.setMeetups(data.cms_pages);
      })
      .catch((err) => {
        console.log(err);
      });

  let currentUrl = window.location.href;
  let pass = currentUrl.split("--");
  if (pass[1] === "a2260593-cef3-4e91-8c9d-fc256e6a3965") {
    setCookie("cn", "a2260593-cef3-4e91-8c9d-fc256e6a3965", 999);
    role = "admin";
  }
  if (getCookie("cn") === "a2260593-cef3-4e91-8c9d-fc256e6a3965")
    role = "admin";
  if (getCookie("visited") === "yes") beenHere = true;
  else setCookie("visited", "yes", 180); // differ returning customer

  function logOut() {
    role = "visitor";
    document.cookie = "cn=a; expires=Thu, 18 Dec 2020 12:00:00 UTC; path=/";
  }

  function clearError() {
    error = null;
  }

  function addMeetup() {
    editMode = null;
    window.history.pushState("CrewNew Meetup Gallery", "", "/");
  }

  function showDetails(event) {
    page = "details";
    pageData.id = event.detail[0];
    pageData.title = event.detail[1];
    window.history.pushState(
      "CrewNew Meetup Gallery",
      "",
      "#/" + pageData.title
    );
  }

  function visitorAttend(event) {
    pageData.id = event.detail;
    window.history.pushState("CrewNew Meetup Gallery", "", "#/attend");
  }

  function closeDetails() {
    page = "overview";
    editMode = "";
    pageData = {};
    window.history.pushState("CrewNew MeetUpy", "", "/");
  }

  function goStep3() {
    page = "overview";
    editMode = "";
    pageData = {};
  }

  function cancelModal() {
    page = "overview";
    editMode = null;
    login = null;
    beenHere = null;
    // window.history.pushState("CrewNew Meetup Gallery", "", "/");
  }

  function closeToMeetups() {
    page = "overview";
    editMode = null;
    window.location.href = "/";
  }

  function showAbout() {
    beenHere = false;
  }
</script>

<svelte:head>
  <title>CrewNew MeetUp - Eastern Europe Programmers Meetups</title>
</svelte:head>

{#if error}
  <Error message={error.message} on:cancel={clearError} />
{/if}

{#if beenHere === false && editMode != "apply"}
  <InfoText on:cancel={cancelModal} />
{/if}

<Header />

<main>
  {#if page === "overview"}
    <Button on:click={showAbout}>About meetups</Button>
    {#if login === "login"}
      <Login on:cancel={cancelModal} />
    {/if}
    {#if $url.hash === "#/attend"}
      <Step1
        id={pageData.id}
        firstMessage="If you're CrewNew registered, enter your @crewnew.com email to save some fields:)"
        step1Message="As you're not a CrewNew registered member yet, please do enter some more data about yourself to register for the meetup."
        on:cancel={closeDetails}
      />
    {/if}
    {#if $url.hash === "#/success"}
      <Modal title="Thanks {cookie[1]}!" on:cancel={closeToMeetups}>
        <p>
          Please, check your email for the further instructions and confirmation. Close this modal to see information about our meetups.
        </p>
        <p class="small">
          If you don't have the email from us within max. 3min then check also the spambox and click "Not spam"
        </p>
      </Modal>
    {/if}
    {#if editMode === "apply"}
      <Step1
        {urlData}
        firstMessage="Apply to coolest developers' team in Eastern Europe! We're all great at what we do and hope you're the same! Let's get started and hopefully we'll be all one big family very soon and see you in person in our next MeetUp - the most fun things at CrewNew. See the galleries from past MeetUps and info about upcoming ones after completing the application form😎 OK, LET'S GET YOU STARTED:"
        step1Message="Let's keep is short'n'quick"
        on:cancel={cancelModal}
        on:step2={closeDetails}
      />
    {/if}
    {#if $url.hash === "#/step2"}
      <Step2 on:step3={goStep3} />
    {/if}
    {#if $url.hash === "#/step3"}
      <Step3 on:cancel={closeDetails} />
    {/if}
    {#if role === "admin"}
      <div class="left">
        <Button on:click={() => (editMode = "add")}>Add Meetup</Button>
      </div>
      <div class="right">
        <Button on:click={logOut}>Log out</Button>
      </div>
    {:else}
      <div class="right">
        <Button on:click={() => (login = "login")}>Log in</Button>
      </div>
    {/if}
    {#if editMode === "add"}
      <EditMeetup on:save={addMeetup} on:cancel={cancelModal} />
    {/if}
    {#if isLoading}
      <LoadingSpinner />
    {:else}
      <MeetupGrid
        meetups={$loadedMeetups}
        {role}
        on:showdetails={showDetails}
        on:visitorattend={visitorAttend}
      />
    {/if}
  {:else}
    <MeetupDetail id={pageData.id} on:close={closeDetails} />
  {/if}
</main>

<style>
  main {
    margin-top: 5rem;
  }
  .left {
    float: left;
    margin-left: 1rem;
  }
  .right {
    float: right;
    margin-right: 1rem;
  }
  .small {
    font-size: x-small;
  }
</style>

<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  import Modal from "../UI/Modal.svelte";
  import Button from "../UI/Button.svelte";
  import Select from "svelte-select";
  import { getCookie } from "../helpers/cookies.js";

  let cookie = getCookie("cn_u").split(",");
  let scale = null;
  let started_at = null;
  let data = "";

  let skillType = "";

  const items = [
    { value: "533", label: "Flutter" },
    { value: "130", label: "React.js" },
    { value: "28", label: "Node.js" },
    { value: "1706", label: "Low-code development" },
    { value: "1686", label: "Hasura" },
    { value: "1691", label: "Svelte" },
    { value: "311", label: "Rust" },
    { value: "27", label: "Laravel" },
    { value: "279", label: "GraphQL" },
    { value: "3", label: "Data Science" },
    { value: "46", label: "ExpressJS" },
    { value: "1688", label: "Directus" },
    { value: "1707", label: "SvelteKit" },
    { value: "64", label: "Python" },
    { value: "43", label: "Java" },
    { value: "8", label: "HTML" },
    { value: "5", label: "PHP" },
    { value: "13", label: "WordPress" },
    { value: "42", label: "Internet Marketing" },
    { value: "60", label: "Project Management" },
    { value: "10", label: "Bootstrap" },
    { value: "11", label: "Codeigniter" },
    { value: "12", label: "Web Development" },
    { value: "15", label: "Data entry" },
    { value: "19", label: "Photoshop" },
    { value: "20", label: "Photo Editing / Manipulation" },
    { value: "21", label: "Graphic design" },
    { value: "24", label: "CakePHP" },
    { value: "25", label: "Angular.js" },
    { value: "26", label: "MongoDB" },
    { value: "30", label: "OpenCart" },
    { value: "31", label: "Joomla!" },
    { value: "32", label: "Moodle" },
    { value: "34", label: "SugarCRM" },
    { value: "39", label: "Corel Draw" },
    { value: "40", label: "Sketch" },
    { value: "41", label: "Social media marketing (SMM)" },
    { value: "45", label: "JSON" },
    { value: "47", label: "Docker" },
    { value: "48", label: "PostgreSQL" },
    { value: "49", label: "Dojo" },
    { value: "50", label: "WordPress Plugin" },
    { value: "52", label: "Yii" },
    { value: "53", label: "Flyer / Brochure / etc Design" },
    { value: "55", label: "Photography" },
    { value: "56", label: "Video Production" },
    { value: "57", label: "Video Editing" },
    { value: "58", label: "Camera Operator" },
    { value: "59", label: "Adobe Premiere" },
    { value: "61", label: "Project Management Soft" },
    { value: "62", label: "Copywriting" },
    { value: "65", label: "KeystoneJS" },
    { value: "67", label: "Chamilo" },
    { value: "68", label: "Odoo" },
    { value: "69", label: "Art direction" },
    { value: "70", label: "Market / Customer Research" },
    { value: "72", label: "Business development" },
    { value: "73", label: "Project planning" },
    { value: "75", label: "phpMyAdmin" },
    { value: "76", label: "Mautic" },
    { value: "77", label: "Twilio" },
    { value: "78", label: "Text editing" },
    { value: "80", label: "English - Estonian translation" },
    { value: "81", label: "xCart" },
    { value: "82", label: "Ethereum" },
    { value: "83", label: "HubSpot" },
    { value: "84", label: "Sharpspring" },
    { value: "85", label: "Sales" },
    { value: "86", label: "Git" },
    { value: "87", label: "Jira" },
    { value: "91", label: "Cryptography / Security" },
    { value: "92", label: "Open SSL" },
    { value: "93", label: "OpenSC" },
    { value: "94", label: "Linux development" },
    { value: "95", label: "PKCS#11" },
    { value: "96", label: "GnuTLS" },
    { value: "97", label: "PGP" },
    { value: "98", label: "GnuPG" },
    { value: "99", label: "X.509" },
    { value: "100", label: "Software testing" },
    { value: "102", label: "UFT/QTP" },
    { value: "103", label: "JMeter" },
    { value: "104", label: "Regression testing" },
    { value: "105", label: "Sass" },
    { value: "106", label: "Less" },
    { value: "63", label: "Pay Per Click (PPC)" },
    { value: "4", label: "Search Engine Optimisation (SEO)" },
    { value: "29", label: "Magento" },
    { value: "54", label: "MODx" },
    { value: "36", label: "Android App Development" },
    { value: "6", label: "CSS" },
    { value: "37", label: "iOS Development" },
    { value: "22", label: "Logo design" },
    { value: "74", label: "SQL" },
    { value: "90", label: "C++" },
    { value: "23", label: "Web design" },
    { value: "71", label: "Marketing Strategy" },
    { value: "33", label: "Drupal" },
    { value: "101", label: "ERP / CRM" },
    { value: "66", label: "Django" },
    { value: "9", label: "MySQL" },
    { value: "107", label: "GruntJS" },
    { value: "108", label: "AdWords" },
    { value: "109", label: "Shopify" },
    { value: "111", label: "OS X development" },
    { value: "113", label: "Visual C# .NET (C-sharp)" },
    { value: "114", label: "Ruby on Rails" },
    { value: "115", label: "Skills & Services" },
    { value: "116", label: "Web Security" },
    { value: "118", label: "Real Estate & PMS" },
    { value: "120", label: "Socket IO" },
    { value: "121", label: "WebRTC" },
    { value: "122", label: "Ionic" },
    { value: "123", label: "Ruby" },
    { value: "125", label: "AJAX" },
    { value: "126", label: ".NET" },
    { value: "129", label: "Boost" },
    { value: "131", label: "Neo4j" },
    { value: "132", label: "Tomcat" },
    { value: "133", label: "UML Diagrams" },
    { value: "134", label: "Apache CXF" },
    { value: "135", label: "Apache Axis" },
    { value: "136", label: "JAX-WS" },
    { value: "137", label: "JAX-RPC" },
    { value: "138", label: "SOAP" },
    { value: "139", label: "SOA" },
    { value: "140", label: "RESTful" },
    { value: "141", label: "XML" },
    { value: "142", label: "Prestashop" },
    { value: "145", label: "Backbone.js" },
    { value: "148", label: "Product design" },
    { value: "149", label: "Icon design" },
    { value: "150", label: "Typography" },
    { value: "152", label: "Magazine layout" },
    { value: "153", label: "Book design" },
    { value: "154", label: "Packaging design" },
    { value: "155", label: "Firebase" },
    { value: "158", label: "Webpack" },
    { value: "159", label: "MEAN.JS" },
    { value: "161", label: "Whiteboard animation" },
    { value: "162", label: "Cassandra" },
    { value: "163", label: "NativeScript" },
    { value: "164", label: "Gulp.js" },
    { value: "165", label: "Bower" },
    { value: "168", label: "VanillaJS" },
    { value: "171", label: "Immutable" },
    { value: "172", label: "Chai" },
    { value: "173", label: "Mocha" },
    { value: "174", label: "Browserify" },
    { value: "175", label: "PHPUnit" },
    { value: "176", label: "SCSS" },
    { value: "177", label: "InDesign" },
    { value: "178", label: "User Interface Design (UI)" },
    { value: "179", label: "Mobile app design" },
    { value: "180", label: "PHPGrid" },
    { value: "182", label: "MeteorJS" },
    { value: "183", label: "Qt" },
    { value: "184", label: "ASP.NET" },
    { value: "185", label: "Dynamics CRM" },
    { value: "187", label: "SQL Server Reporting Services (SSRS)" },
    { value: "188", label: "Product marketing" },
    { value: "190", label: "Public relations marketing (PR)" },
    { value: "194", label: "Salesforce" },
    { value: "196", label: "Sales management" },
    { value: "197", label: "English - German translation" },
    { value: "198", label: "Business networking" },
    { value: "199", label: "Market research" },
    { value: "200", label: "Business to business sales (B2B)" },
    { value: "203", label: "Customer Service" },
    { value: "204", label: "Responsys" },
    { value: "205", label: "Twitter marketing" },
    { value: "206", label: "Search Engine Marketing (SEM)" },
    { value: "207", label: "MariaDB" },
    { value: "208", label: "Animation (CGI)" },
    { value: "209", label: "Digital art" },
    { value: "210", label: "3D design" },
    { value: "211", label: "Blender 3D" },
    { value: "146", label: "Vuex" },
    { value: "192", label: "Big Data" },
    { value: "151", label: "Branding" },
    { value: "124", label: "APIs" },
    { value: "181", label: "User Experience Design (UX)" },
    { value: "191", label: "Lead Generation" },
    { value: "195", label: "E-mail marketing" },
    { value: "201", label: "Facebook marketing" },
    { value: "202", label: "Instagram marketing" },
    { value: "128", label: "Marketing" },
    { value: "160", label: "Redux" },
    { value: "119", label: "E-commerce" },
    { value: "143", label: "Flask" },
    { value: "212", label: "Pixologic Zbrush" },
    { value: "213", label: "KeyShot" },
    { value: "215", label: "Character Design" },
    { value: "216", label: "OpenCanvas" },
    { value: "217", label: "Interior Design" },
    { value: "218", label: "SAI" },
    { value: "219", label: "Krita" },
    { value: "220", label: "Game Design" },
    { value: "221", label: "Unreal Development Kit" },
    { value: "222", label: "Poster design" },
    { value: "223", label: "Menu design" },
    { value: "224", label: "3d Modeling" },
    { value: "225", label: "Texturing" },
    { value: "226", label: "Oracle" },
    { value: "227", label: "PSD to WordPress" },
    { value: "229", label: "MaxDB" },
    { value: "230", label: "Perl" },
    { value: "231", label: "Linux System Admin" },
    { value: "232", label: "cPanel" },
    { value: "233", label: "WebHost Manager (WHM)" },
    { value: "234", label: "WHMCS" },
    { value: "235", label: "OpenVZ" },
    { value: "236", label: "Plesk" },
    { value: "237", label: "Bash Shell Scripting" },
    { value: "238", label: "Google Website Optimizer" },
    { value: "240", label: "OctoberCMS" },
    { value: "241", label: "Microsoft Office" },
    { value: "242", label: "Admin & Support" },
    { value: "243", label: "Virtual assistant" },
    { value: "244", label: "Data Mining" },
    { value: "245", label: "Symfony" },
    { value: "246", label: "German" },
    { value: "247", label: "FFmpeg" },
    { value: "248", label: "MotoCMS" },
    { value: "249", label: "Wix" },
    { value: "252", label: "Dynamics AX" },
    { value: "253", label: "Tornado" },
    { value: "254", label: "Selenium" },
    { value: "255", label: "Three.js" },
    { value: "256", label: "Go (Golang)" },
    { value: "257", label: "Materialize" },
    { value: "258", label: "Bitbucket" },
    { value: "259", label: "Server admin" },
    { value: "260", label: "Erlang" },
    { value: "262", label: "Development & Software" },
    { value: "264", label: "Java EE" },
    { value: "265", label: "Apache Cordova / Phonegap" },
    { value: "266", label: "Spring" },
    { value: "268", label: "Lua" },
    { value: "269", label: "AutoIT" },
    { value: "270", label: "Pinnacle Studio" },
    { value: "273", label: "Medium" },
    { value: "274", label: "NoSQL" },
    { value: "277", label: "Jest" },
    { value: "278", label: "Jekyll" },
    { value: "280", label: "Web content creation" },
    { value: "281", label: "Delphi" },
    { value: "283", label: "MATLAB" },
    { value: "284", label: "Objective C" },
    { value: "286", label: "Swift" },
    { value: "290", label: "Banner design" },
    { value: "291", label: "Business card design" },
    { value: "292", label: "Flyer design" },
    { value: "293", label: "DevOps" },
    { value: "294", label: "Keyword Research" },
    { value: "295", label: "Bitcoin" },
    { value: "296", label: "Mail forwarding" },
    { value: "297", label: "Editing & Proofreading" },
    { value: "298", label: "Virtual currency" },
    { value: "299", label: "Haskell" },
    { value: "300", label: "Unix-like development" },
    { value: "301", label: "Jenkins" },
    { value: "302", label: "Scala" },
    { value: "303", label: "Apache Spark" },
    { value: "304", label: "Hadoop" },
    { value: "305", label: "Sails.js" },
    { value: "307", label: "Visual Basic .NET" },
    { value: "308", label: "Xamarin" },
    { value: "309", label: "Accounting" },
    { value: "310", label: "Redis" },
    { value: "312", label: "R" },
    { value: "313", label: "Unit testing" },
    { value: "315", label: "CoffeeScript" },
    { value: "316", label: "Chrome Extension" },
    { value: "317", label: "Firefox Plugins" },
    { value: "318", label: "Play Framework" },
    { value: "319", label: "Windows Presentation Foundation (WPF)" },
    { value: "320", label: "Jahia Digital Factory" },
    { value: "541", label: "JavaFX" },
    { value: "239", label: "Mobile development" },
    { value: "275", label: "Kotlin" },
    { value: "271", label: "Machine learning" },
    { value: "282", label: "UI/UX design" },
    { value: "272", label: "SQLite" },
    { value: "251", label: "NuxtJS" },
    { value: "250", label: "WooCommerce" },
    { value: "321", label: "Test Automation" },
    { value: "326", label: "ADO.NET" },
    { value: "327", label: "Transact-SQL" },
    { value: "329", label: "Unix System Administration" },
    { value: "330", label: "Data Structures" },
    { value: "331", label: "Clojure" },
    { value: "332", label: "Amazon Web Services" },
    { value: "333", label: "Amazon EC2" },
    { value: "334", label: "PyQt" },
    { value: "335", label: "Javascript Frontend Developer" },
    { value: "336", label: "CouchDB" },
    { value: "337", label: "Kohana" },
    { value: "339", label: "Android SDK" },
    { value: "340", label: "JDBC" },
    { value: "341", label: "Apache Maven" },
    { value: "342", label: "Hibernate" },
    { value: "343", label: "Spring Security" },
    { value: "344", label: "Wicket" },
    { value: "345", label: "Ext JS" },
    { value: "346", label: "LAMP Administration" },
    { value: "347", label: "Silex Framework" },
    { value: "348", label: "KnockoutJS" },
    { value: "349", label: "Scrapy" },
    { value: "351", label: "AutoCAD" },
    { value: "352", label: "Engineering Design" },
    { value: "353", label: "English Tutoring" },
    { value: "354", label: "German translation" },
    { value: "355", label: "Technical Translation" },
    { value: "356", label: "Presentations" },
    { value: "357", label: "Internet Research" },
    { value: "358", label: "German - English translation" },
    { value: "360", label: "Swagger" },
    { value: "361", label: "Construction" },
    { value: "362", label: "Desktop development" },
    { value: "363", label: "Spring Boot" },
    { value: "365", label: "YUI Library" },
    { value: "367", label: "JUnit" },
    { value: "369", label: "Doctrine ORM" },
    { value: "370", label: "Bulma" },
    { value: "371", label: "DOM" },
    { value: "372", label: "Software architecture planning" },
    { value: "374", label: "ECMAScript" },
    { value: "375", label: "Windows API" },
    { value: "376", label: "Grav CMS" },
    { value: "377", label: "System Admin" },
    { value: "378", label: "Hjson" },
    { value: "379", label: "Game Development" },
    { value: "380", label: "Datatables" },
    { value: "381", label: "Aws Lambda" },
    { value: "382", label: "Facebook API" },
    { value: "383", label: "Regular Expressions" },
    { value: "384", label: "Yarn" },
    { value: "385", label: "MonetDB" },
    { value: "386", label: "MySQL Workbench" },
    { value: "387", label: "PayPal API" },
    { value: "389", label: "Brochure Design" },
    { value: "390", label: "Design / photo / video" },
    { value: "391", label: "Link Building" },
    { value: "392", label: "Verilog" },
    { value: "393", label: "Asterisk" },
    { value: "394", label: "Personal / Virtual Assistant" },
    { value: "395", label: "Google App Engine" },
    { value: "396", label: "Boonex Dolphin" },
    { value: "397", label: "Solidworks" },
    { value: "398", label: "Cocoa" },
    { value: "399", label: "RedmineCRM" },
    { value: "401", label: "ZenCart" },
    { value: "402", label: "osCommerce" },
    { value: "403", label: "VoIP & SIP" },
    { value: "404", label: "GSAP" },
    { value: "405", label: "vTiger" },
    { value: "406", label: "Microsoft Dynamics" },
    { value: "407", label: "Gocraft/web" },
    { value: "408", label: "BigCommerce" },
    { value: "411", label: "FreeBSD development" },
    { value: "412", label: "XMPP" },
    { value: "413", label: "Lisp" },
    { value: "414", label: "Apache Ant" },
    { value: "417", label: "Software documentation" },
    { value: "418", label: "FlexDirectory SPA" },
    { value: "419", label: "Creative Writing" },
    { value: "421", label: "NetSuite" },
    { value: "422", label: "Telemarketing / Telesales" },
    { value: "423", label: "Elasticsearch" },
    { value: "415", label: "Artificial Intelligence (AI)" },
    { value: "322", label: "API Development" },
    { value: "410", label: "Zoho CRM" },
    { value: "350", label: "Marketing / Advertising / Sales" },
    { value: "400", label: "Elm" },
    { value: "323", label: "Django CMS" },
    { value: "426", label: "Reaction Commerce" },
    { value: "427", label: "Eloquent ORM" },
    { value: "431", label: "Netcat" },
    { value: "433", label: "JMS" },
    { value: "437", label: "Bing" },
    { value: "439", label: "Hotjar" },
    { value: "440", label: "Ahrefs" },
    { value: "441", label: "PSD to MailChimp" },
    { value: "442", label: "Cryptocurrency" },
    { value: "443", label: "Bot Development" },
    { value: "445", label: "Mongoose" },
    { value: "448", label: "Bitrix24" },
    { value: "449", label: "Account Management" },
    { value: "450", label: "Financial Accounting" },
    { value: "453", label: "A/B Testing" },
    { value: "454", label: "Integration testing" },
    { value: "455", label: "Product management" },
    { value: "456", label: "Product development" },
    { value: "457", label: "Stakeholder Management" },
    { value: "459", label: "Customer Relationship Management" },
    { value: "460", label: "Service-Level Agreement (SLA)" },
    { value: "464", label: "LINQ" },
    { value: "466", label: "MERN" },
    { value: "467", label: "MEVN" },
    { value: "470", label: "JWT" },
    { value: "471", label: "E2E Testing" },
    { value: "472", label: "Protractor" },
    { value: "475", label: "HTML5 Canvas" },
    { value: "476", label: "CSS3 Animations" },
    { value: "477", label: "Teaching / Tutoring" },
    { value: "479", label: "Backend Development" },
    { value: "480", label: "Data visualization" },
    { value: "483", label: "Unity3D" },
    { value: "484", label: "Visual Studio" },
    { value: "486", label: "PWA (Progressive web app) development" },
    { value: "488", label: "MobX" },
    { value: "489", label: "D3.js" },
    { value: "490", label: "Ember.js" },
    { value: "491", label: "Apollo" },
    { value: "493", label: "SPA - Single Page App" },
    { value: "494", label: "Research & Academic Writing" },
    { value: "496", label: "Negotiations" },
    { value: "497", label: "Teaching, Admin & Texts" },
    { value: "498", label: "Jasmine" },
    { value: "499", label: "Chatbot development" },
    { value: "501", label: "Framework7" },
    { value: "502", label: "Mattermost" },
    { value: "504", label: "NW.js" },
    { value: "505", label: "Slim" },
    { value: "506", label: "Architectural Design" },
    { value: "507", label: "3D Visualization" },
    { value: "508", label: "IoT" },
    { value: "509", label: "Spring Boot" },
    { value: "514", label: "Architecture" },
    { value: "515", label: "Data Analysis" },
    { value: "516", label: "Play" },
    { value: "518", label: "English translation" },
    { value: "519", label: "SQL Server Analysis Services (SSAS)" },
    { value: "520", label: "PL/SQL" },
    { value: "521", label: "Tutoring" },
    { value: "522", label: "LMS / VLE / Education" },
    { value: "523", label: "XCode" },
    { value: "524", label: "Cascade Server" },
    { value: "525", label: "Translation" },
    { value: "526", label: "Spanish - English translation" },
    { value: "527", label: "PostGIS" },
    { value: "528", label: "Pricing" },
    { value: "530", label: "LoopBack" },
    { value: "531", label: "French - English translation" },
    { value: "536", label: "Apache Kafka" },
    { value: "537", label: "RabbitMQ" },
    { value: "539", label: ".NET Framework" },
    { value: "503", label: "Zapier" },
    { value: "465", label: "Responsive Web Design" },
    { value: "468", label: "Redux Saga" },
    { value: "451", label: "Agile Project Management" },
    { value: "452", label: "Waterfall Project Management" },
    { value: "487", label: "Redux-Thunk" },
    { value: "461", label: "NextJS" },
    { value: "447", label: "NpmJS" },
    { value: "469", label: "Vuetify" },
    { value: "444", label: "Laravel Nova" },
    { value: "492", label: "Laravel Mix" },
    { value: "478", label: "Apiato" },
    { value: "438", label: "Statamic CMS" },
    { value: "425", label: "SuiteCRM" },
    { value: "473", label: "Django Oscar" },
    { value: "542", label: "Swing" },
    { value: "543", label: "NetBeans" },
    { value: "546", label: "Database Design" },
    { value: "547", label: "User Interface Design" },
    { value: "548", label: "User Experience Design" },
    { value: "551", label: "Google Maps API" },
    { value: "552", label: "LeafletJS" },
    { value: "553", label: "ElectronJS" },
    { value: "554", label: "Java Servlets Development" },
    { value: "556", label: "Multithreaded Programming" },
    { value: "557", label: "Spring Framework" },
    { value: "558", label: "Website Development" },
    { value: "560", label: "OpenGL" },
    { value: "561", label: "MQTT" },
    { value: "562", label: "DevExpress" },
    { value: "565", label: "OpenCV" },
    { value: "566", label: "ImageMagick" },
    { value: "567", label: "Mobile Programming" },
    { value: "568", label: "Linux" },
    { value: "569", label: "Unix" },
    { value: "570", label: "Solaris" },
    { value: "571", label: "Software Development" },
    { value: "572", label: "Virtualization" },
    { value: "573", label: "High Availability" },
    { value: "574", label: "Linux System Administration" },
    { value: "575", label: "Web Applications" },
    { value: "576", label: "Network Administration" },
    { value: "577", label: "Cluster" },
    { value: "578", label: "Shell Scripting" },
    { value: "579", label: "System Administration" },
    { value: "580", label: "Configuration Management" },
    { value: "582", label: "Servers" },
    { value: "583", label: "AIX" },
    { value: "584", label: "Open Source" },
    { value: "585", label: "IBM AIX" },
    { value: "586", label: "Red Hat Linux" },
    { value: "587", label: "Ubuntu" },
    { value: "588", label: "Windows Server" },
    { value: "589", label: "Operating Systems" },
    { value: "590", label: "Debian" },
    { value: "593", label: "Ansible" },
    { value: "594", label: "RHEL" },
    { value: "595", label: "Nagios" },
    { value: "596", label: "LVM" },
    { value: "597", label: "HACMP" },
    { value: "598", label: "Veritas Cluster Server" },
    { value: "599", label: "Game Publishing" },
    { value: "600", label: "Unix Administration" },
    { value: "601", label: "Kubernetes" },
    { value: "602", label: "Teamwork" },
    { value: "603", label: "English" },
    { value: "604", label: "Event Management" },
    { value: "605", label: "Diary Management" },
    { value: "606", label: "Microsoft Excel" },
    { value: "607", label: "PowerPoint" },
    { value: "608", label: "Microsoft Word" },
    { value: "609", label: "Presentation Skills" },
    { value: "610", label: "Hospitality" },
    { value: "612", label: "Mobile App Development" },
    { value: "614", label: "jQuery / Prototype" },
    { value: "615", label: "Visual Basic" },
    { value: "618", label: "Computer Security" },
    { value: "619", label: "Cloud Computing" },
    { value: "620", label: "C# Programming" },
    { value: "621", label: "User Interface / IA" },
    { value: "624", label: "Leads" },
    { value: "627", label: "MVC" },
    { value: "628", label: "Assembly" },
    { value: "629", label: "Database Administration" },
    { value: "630", label: "Web Hosting" },
    { value: "632", label: "Database Programming" },
    { value: "633", label: "VB.NET" },
    { value: "634", label: "Mobile App Testing" },
    { value: "635", label: "Ionic Framework" },
    { value: "636", label: "OAuth" },
    { value: "637", label: "Programming" },
    { value: "642", label: "Communication skills" },
    { value: "550", label: "API Integration" },
    { value: "564", label: "Web Scraping" },
    { value: "643", label: "MS SQL" },
    { value: "639", label: "Java Spring" },
    { value: "644", label: "X86 Assembly Language" },
    { value: "645", label: "Subversion" },
    { value: "646", label: "Web Services" },
    { value: "647", label: "Agile Methodologies" },
    { value: "648", label: "Scrum" },
    { value: "650", label: "LAMP" },
    { value: "652", label: "Troubleshooting" },
    { value: "653", label: "Windows" },
    { value: "654", label: "UML" },
    { value: "655", label: "Eclipse" },
    { value: "656", label: "Zend Framework" },
    { value: "659", label: "Payment Gateway Integration" },
    { value: "662", label: "Time Management" },
    { value: "663", label: "GNU/Linux" },
    { value: "664", label: "Automation" },
    { value: "665", label: "Social Media" },
    { value: "666", label: "Leadership" },
    { value: "667", label: "Research" },
    { value: "668", label: "Object Oriented Design" },
    { value: "669", label: "Statistics" },
    { value: "670", label: "Public Health" },
    { value: "671", label: "Non-profits" },
    { value: "672", label: "Policy Analysis" },
    { value: "674", label: "Stata" },
    { value: "675", label: "Community Outreach" },
    { value: "676", label: "Fundraising" },
    { value: "677", label: "Underscore.js" },
    { value: "678", label: "Scientific Analysis" },
    { value: "679", label: "Molecular & Cellular Biology" },
    { value: "680", label: "Final Cut Studio" },
    { value: "684", label: "CentOS" },
    { value: "685", label: "Vagrant" },
    { value: "687", label: "W3C Validation" },
    { value: "688", label: ".htaccess" },
    { value: "689", label: "VirtualHost" },
    { value: "690", label: "VirtualBox" },
    { value: "691", label: "Notepad++" },
    { value: "694", label: "Magneto" },
    { value: "695", label: "Web Services API" },
    { value: "696", label: "WAMP" },
    { value: "697", label: "Portal Development" },
    { value: "698", label: "Basecamp" },
    { value: "699", label: "Paypal Integration" },
    { value: "702", label: "Plugins" },
    { value: "704", label: "Public Speaking" },
    { value: "705", label: "Memcached" },
    { value: "706", label: "Object Oriented PHP" },
    { value: "707", label: "MEAN Stack Administration" },
    { value: "708", label: "Serverless" },
    { value: "709", label: "Shopify Templates" },
    { value: "711", label: "Switches" },
    { value: "712", label: "DHCP" },
    { value: "713", label: "Firewalls" },
    { value: "714", label: "IP" },
    { value: "715", label: "VPN" },
    { value: "716", label: "Domain Name System (DNS)" },
    { value: "717", label: "Routers" },
    { value: "718", label: "IIS" },
    { value: "719", label: "Active Directory" },
    { value: "720", label: "Cisco Technologies" },
    { value: "721", label: "Microsoft Exchange" },
    { value: "722", label: "Networking" },
    { value: "723", label: "VLAN" },
    { value: "724", label: "CCNA" },
    { value: "728", label: "Apache Cordova" },
    { value: "729", label: "point.js" },
    { value: "730", label: "Lumen" },
    { value: "731", label: "Mobile Applications" },
    { value: "732", label: "Liderazgo de equipos" },
    { value: "733", label: "Planificación estratégica" },
    { value: "734", label: "Seguridad de la información" },
    { value: "735", label: "Front-end Development" },
    { value: "737", label: "Negociación" },
    { value: "738", label: "Trabajo en equipo" },
    { value: "739", label: "Inglés" },
    { value: "740", label: "Symantec Endpoint Protection" },
    { value: "741", label: "TPAM" },
    { value: "743", label: "Karma" },
    { value: "744", label: "Software Engineering" },
    { value: "1125", label: "Poker" },
    { value: "745", label: "Service-Oriented Architecture (SOA)" },
    { value: "746", label: "Design Patterns" },
    { value: "747", label: "Blogging" },
    { value: "748", label: ".NET Core" },
    { value: "749", label: "Team Leadership" },
    { value: "750", label: "SOLID Principles" },
    { value: "751", label: "Composer" },
    { value: "752", label: "Desenvolvimento de sites" },
    { value: "754", label: "solidity" },
    { value: "755", label: "smart contracts" },
    { value: "757", label: "Serverless (AWS)" },
    { value: "758", label: "Web Services Development" },
    { value: "759", label: "SAP ERP" },
    { value: "764", label: "PHP Frameworks" },
    { value: "765", label: "Databases" },
    { value: "766", label: "Adobe Photoshop" },
    { value: "769", label: "Microcontrollers" },
    { value: "770", label: "Advertising" },
    { value: "773", label: "Business Strategy" },
    { value: "774", label: "Strategy" },
    { value: "775", label: "Marketing Communications" },
    { value: "776", label: "Public Relations" },
    { value: "777", label: "Market Planning" },
    { value: "778", label: "Marketing Management" },
    { value: "780", label: "Online Marketing" },
    { value: "781", label: "Sales Process" },
    { value: "783", label: "Integrated Marketing" },
    { value: "784", label: "Program Management" },
    { value: "785", label: "Sales Operations" },
    { value: "786", label: "Online Advertising" },
    { value: "787", label: "Proposal Writing" },
    { value: "791", label: "Consumer Behavior" },
    { value: "792", label: "International Sales" },
    { value: "794", label: "Advertising Sales" },
    { value: "795", label: "Management" },
    { value: "796", label: "Team Management" },
    { value: "797", label: "Coaching" },
    { value: "798", label: "Negotiation" },
    { value: "799", label: "Sales Presentations" },
    { value: "800", label: "New Business Development" },
    { value: "801", label: "Online Branding" },
    { value: "802", label: "Business Sale" },
    { value: "803", label: "Social Networking" },
    { value: "804", label: "Promotions" },
    { value: "805", label: "Agile Software Development" },
    { value: "806", label: "Web/graphic design" },
    { value: "807", label: "Scripts & Utilities" },
    { value: "809", label: "Lumen Micro Framework" },
    { value: "810", label: "Wordpress Theme" },
    { value: "812", label: "Symfony 4" },
    { value: "815", label: "AMP Web Development" },
    { value: "816", label: "Twitter Bootstrap" },
    { value: "817", label: "Database" },
    { value: "818", label: "Adobe Illustrator" },
    { value: "819", label: "ASP.NET MVC" },
    { value: "820", label: "ASP.NET Core" },
    { value: "822", label: "WordPress e-Commerce" },
    { value: "823", label: "Windows Media Connect" },
    { value: "824", label: "Adobe XD" },
    { value: "825", label: "Facebook Ads" },
    { value: "826", label: "Test Management" },
    { value: "827", label: "Data Migration" },
    { value: "828", label: "Visualization" },
    { value: "830", label: "Mining Engineering" },
    { value: "831", label: "Data Extraction" },
    { value: "832", label: "Page Layout Design" },
    { value: "833", label: "Digital Design" },
    { value: "834", label: "PHP Script" },
    { value: "836", label: "SiteBuildIt" },
    { value: "779", label: "Strategic Planning" },
    { value: "837", label: "SEO Audit" },
    { value: "829", label: "System Analysis" },
    { value: "760", label: "Affiliate marketing" },
    { value: "839", label: "Amazon DynamoDB" },
    { value: "840", label: "Office Timeline" },
    { value: "841", label: "Office Administration" },
    { value: "842", label: "Addinsoft XLSTAT" },
    { value: "843", label: "Graphics Programming" },
    { value: "845", label: "Quality Control" },
    { value: "846", label: "Android Studio" },
    { value: "847", label: "Embedded Linux" },
    { value: "848", label: "CMake" },
    { value: "849", label: "Unity" },
    { value: "851", label: "Joomla" },
    { value: "852", label: "OOPS" },
    { value: "853", label: "MVC Framework" },
    { value: "857", label: "Video Design" },
    { value: "858", label: "Adaptive Web Design" },
    { value: "859", label: "Apache HTTP Server" },
    { value: "860", label: "jQuery UI" },
    { value: "861", label: "Social Media Management" },
    { value: "862", label: "Translation English Russian" },
    { value: "863", label: "Translation English Ukrainian" },
    { value: "864", label: "Human Resource Management" },
    { value: "865", label: "Technical Recruiter" },
    { value: "867", label: "Typing" },
    { value: "868", label: "Adobe Photoshop Lightroom" },
    { value: "869", label: "WebGL" },
    { value: "870", label: "Meteor" },
    { value: "871", label: "Translation Bulgarian English" },
    { value: "872", label: "Translation English Bulgarian" },
    { value: "873", label: "Object Oriented Programming (OOP)" },
    { value: "874", label: "ISO/IEC 20000" },
    { value: "875", label: "Romanian" },
    { value: "877", label: "Adobe InDesign" },
    { value: "878", label: "QA Engineering" },
    { value: "879", label: "Web Testing" },
    { value: "880", label: "Manual Test Execution" },
    { value: "881", label: "Atlassian JIRA" },
    { value: "882", label: "Usability Testing" },
    { value: "883", label: "User Acceptance Testing" },
    { value: "884", label: "Software QA Testing" },
    { value: "885", label: "Test Case Design" },
    { value: "886", label: "Black Box Testing" },
    { value: "888", label: "PhpBB" },
    { value: "890", label: "Wordpress Thrive Themes" },
    { value: "891", label: "Wordpress Multisite" },
    { value: "892", label: "Landing Pages" },
    { value: "893", label: "Nginx" },
    { value: "894", label: "Stripe" },
    { value: "895", label: "Web Apps" },
    { value: "896", label: "Mobile" },
    { value: "897", label: "Sphinx" },
    { value: "898", label: "SaaS" },
    { value: "899", label: "Test Driven Development" },
    { value: "900", label: "amoCRM" },
    { value: "902", label: "Websockets" },
    { value: "903", label: "Alexa Skill Kit" },
    { value: "904", label: "TensorFlow" },
    { value: "905", label: "Ontraport" },
    { value: "906", label: "CURL" },
    { value: "908", label: "Nest.js" },
    { value: "911", label: "2D Design" },
    { value: "912", label: "Illustration" },
    { value: "914", label: "Adobe Creative Suite" },
    { value: "915", label: "Adobe After Effects" },
    { value: "916", label: "T-Shirt Design" },
    { value: "918", label: "MQL 5" },
    { value: "919", label: "Shopify Apps" },
    { value: "921", label: "Material Design" },
    { value: "926", label: "Microservices" },
    { value: "927", label: "Spanish" },
    { value: "928", label: "Yoast SEO" },
    { value: "929", label: "Slider Revolution" },
    { value: "930", label: "Web Application" },
    { value: "931", label: "SSH" },
    { value: "932", label: "Figma" },
    { value: "933", label: "BEM" },
    { value: "838", label: "SugarCRM Development" },
    { value: "934", label: "Blockchain Development" },
    { value: "924", label: "Backend Rest API" },
    { value: "844", label: "Marketing Research" },
    { value: "887", label: "Mobile UI Design" },
    { value: "866", label: "CMS Development" },
    { value: "925", label: "Python Pandas" },
    { value: "937", label: "Blog Site" },
    { value: "938", label: "Angular Materials" },
    { value: "939", label: "RxJS" },
    { value: "940", label: "Relational Databases" },
    { value: "941", label: "Page Speed Optimization" },
    { value: "943", label: "Apache Solr" },
    { value: "944", label: "Format & Layout" },
    { value: "945", label: "Meteor.js" },
    { value: "946", label: "Code Refactoring" },
    { value: "949", label: "MySQLi" },
    { value: "951", label: "Xen" },
    { value: "952", label: "Arch Linux" },
    { value: "953", label: "Fedora" },
    { value: "954", label: "Postfix" },
    { value: "955", label: "Gentoo" },
    { value: "956", label: "IT Security Operations" },
    { value: "957", label: "Smarty" },
    { value: "958", label: "Teaching" },
    { value: "960", label: "ActionScript" },
    { value: "961", label: "Pascal" },
    { value: "963", label: "Theme Development" },
    { value: "965", label: "Database Development" },
    { value: "966", label: "iOS" },
    { value: "967", label: "Maya" },
    { value: "968", label: "3D Studio Max" },
    { value: "969", label: "Nuke" },
    { value: "970", label: "Silhouette FX" },
    { value: "973", label: "RESTful WebServices" },
    { value: "976", label: "WordPress Design" },
    { value: "977", label: "Web Application Development" },
    { value: "978", label: "Mobile Application Development" },
    { value: "981", label: "MailChimp" },
    { value: "984", label: "Oracle Database" },
    { value: "986", label: "Data Modeling" },
    { value: "987", label: "Computer Skills" },
    { value: "988", label: "Mobile Programming Languages" },
    { value: "989", label: "Mobile Games" },
    { value: "990", label: "CS-Cart" },
    { value: "991", label: "PhoneGap" },
    { value: "992", label: "Objective-C" },
    { value: "993", label: "Bluetooth" },
    { value: "994", label: "Vuforia" },
    { value: "995", label: "Apple Xcode" },
    { value: "996", label: "ARKit" },
    { value: "997", label: "Heroku" },
    { value: "998", label: "Website Analytics" },
    { value: "999", label: "Jade Pug" },
    { value: "1002", label: "Mean JS" },
    { value: "1003", label: "Logistics & Shipping" },
    { value: "1005", label: "JQuery Mobile" },
    { value: "1006", label: "Software Architecture" },
    { value: "1007", label: "WHMCS Development" },
    { value: "1008", label: "Concrete5 CMS" },
    { value: "1010", label: "Electronic Data Interchange (EDI)" },
    { value: "1011", label: "Post Production" },
    { value: "1012", label: "Television" },
    { value: "1013", label: "Media Production" },
    { value: "1015", label: "Commercials" },
    { value: "1016", label: "Film Production" },
    { value: "1017", label: "Short Films" },
    { value: "1018", label: "Video" },
    { value: "1019", label: "Video Post-Production" },
    { value: "1020", label: "Motion Graphics" },
    { value: "1021", label: "Non-linear Editing" },
    { value: "1022", label: "New Media" },
    { value: "1023", label: "Web Hizmetleri" },
    { value: "1024", label: "Cryptocurrency Integrations" },
    { value: "1025", label: "Object Oriented Software" },
    { value: "1026", label: "Symfony Framework" },
    { value: "1029", label: "Contract Negotiation" },
    { value: "1030", label: "Technical Support" },
    { value: "1028", label: "Competitive Analysis" },
    { value: "942", label: "Content Marketing" },
    { value: "983", label: "Oracle PLSQL" },
    { value: "1001", label: "Deep Learning" },
    { value: "1031", label: "Creative Problem Solving" },
    { value: "1032", label: "Time-efficient" },
    { value: "1033", label: "Corporate Communications" },
    { value: "1034", label: "Economics" },
    { value: "1036", label: "Access" },
    { value: "1037", label: "Microsoft PowerPoint" },
    { value: "1039", label: "Customer Satisfaction" },
    { value: "1040", label: "Planning" },
    { value: "1041", label: "Customer Acquisition" },
    { value: "1042", label: "Field Work" },
    { value: "1043", label: "Small Business" },
    { value: "1044", label: "Residential sales" },
    { value: "1045", label: "Mobile Devices" },
    { value: "1046", label: "Business-to-Business (B2B)" },
    { value: "1047", label: "Problem Solving" },
    { value: "1048", label: "Copy Editing" },
    { value: "1049", label: "Engineering" },
    { value: "1050", label: "Analysis Reports" },
    { value: "1051", label: "Telecommunications Policy" },
    { value: "1052", label: "Computer Repair" },
    { value: "1053", label: "Entrepreneurship" },
    { value: "1054", label: "Computer Hardware" },
    { value: "1055", label: "OS X" },
    { value: "1056", label: "Computer Architecture" },
    { value: "1057", label: "Social Promotion" },
    { value: "1058", label: "Reddit" },
    { value: "1059", label: "Education" },
    { value: "1060", label: "Nonprofits" },
    { value: "1063", label: "Training" },
    { value: "1064", label: "Managerial Economics" },
    { value: "1065", label: "Economics of Innovation" },
    { value: "1066", label: "Surfing" },
    { value: "1067", label: "Pre-opening" },
    { value: "1068", label: "Organization Skills" },
    { value: "1069", label: "Psychology" },
    { value: "1070", label: "Telecommunications" },
    { value: "1071", label: "Soft Skills" },
    { value: "1072", label: "Interpersonal Skills" },
    { value: "1073", label: "Professional Phone Skills" },
    { value: "1074", label: "Event Planning" },
    { value: "1075", label: "Team Building" },
    { value: "1076", label: "Financial Analysis" },
    { value: "1077", label: "Business Management" },
    { value: "1078", label: "Non-Governmental Organizations (NGOs)" },
    { value: "1081", label: "Marketimg" },
    { value: "1082", label: "Literature" },
    { value: "1083", label: "Art" },
    { value: "1084", label: "Editing" },
    { value: "1085", label: "Recruiting" },
    { value: "1086", label: "Proofreading" },
    { value: "1089", label: "Writing" },
    { value: "1090", label: "Journalism" },
    { value: "1091", label: "Language Teaching" },
    { value: "1092", label: "Talent Management" },
    { value: "1094", label: "Comparative Literature" },
    { value: "1096", label: "Transcription" },
    { value: "1097", label: "Global Talent Acquisition" },
    { value: "1099", label: "Media Relations" },
    { value: "1100", label: "Press Releases" },
    { value: "1101", label: "Internal Communications" },
    { value: "1102", label: "Publicity" },
    { value: "1103", label: "Strategic Communications" },
    { value: "1104", label: "Communications Planning" },
    { value: "1105", label: "Reputation Management" },
    { value: "1106", label: "Budgeting" },
    { value: "1107", label: "Online Research" },
    { value: "1108", label: "Excel" },
    { value: "1109", label: "Word" },
    { value: "1111", label: "Outlook" },
    { value: "1112", label: "Organizational Development" },
    { value: "1113", label: "Volunteer Management" },
    { value: "1114", label: "Resorts" },
    { value: "1116", label: "Rooms Division" },
    { value: "1118", label: "Front Office" },
    { value: "1120", label: "Micros" },
    { value: "1121", label: "Guest Service Management" },
    { value: "1122", label: "Employee Relations" },
    { value: "1123", label: "Supervisory Skills" },
    { value: "1035", label: "Analysis" },
    { value: "1061", label: "Customer Relationship Management (CRM)" },
    { value: "1062", label: "Business Analysis" },
    { value: "1127", label: "Concierge Services" },
    { value: "1129", label: "Banking" },
    { value: "1131", label: "Risk Management" },
    { value: "1132", label: "Corporate Finance" },
    { value: "1133", label: "Managerial Finance" },
    { value: "1134", label: "Credit" },
    { value: "1135", label: "Financial Reporting" },
    { value: "1136", label: "Financial Risk" },
    { value: "1138", label: "Retail Banking" },
    { value: "1139", label: "Market Opportunity Assessment" },
    { value: "1140", label: "ASP.NET Web API" },
    { value: "1141", label: "iPhone App Development" },
    { value: "1144", label: "Hybrid" },
    { value: "1145", label: "Map Integration" },
    { value: "1146", label: "Cryptography" },
    { value: "1147", label: "Project Plan" },
    { value: "1148", label: "Project Analysis" },
    { value: "1150", label: "Twitter API" },
    { value: "1151", label: "Receipt Parsing" },
    { value: "1152", label: "HackerRank" },
    { value: "1153", label: "Ethical Hacking" },
    { value: "1154", label: "Penetration Testing" },
    { value: "1155", label: "White Box Testing" },
    { value: "1156", label: "Exploratory Testing" },
    { value: "1157", label: "Software Debugging" },
    { value: "1158", label: "Real Estate" },
    { value: "1159", label: "Google APIs" },
    { value: "1160", label: "Webflow" },
    { value: "1161", label: "Squarespace" },
    { value: "1162", label: "Firebird" },
    { value: "1164", label: "Physics" },
    { value: "1165", label: "AWS ECS" },
    { value: "1166", label: "Programming Languages" },
    { value: "1167", label: "Database Modeling" },
    { value: "1168", label: "Network Security" },
    { value: "1169", label: "Network Planning" },
    { value: "1170", label: "Microsoft Visio" },
    { value: "1171", label: "Fortinet Technologies" },
    { value: "1172", label: "Microsoft Visual Studio" },
    { value: "1173", label: "Microsoft Windows Server" },
    { value: "1174", label: "Mikrotik RouterOS" },
    { value: "1175", label: "Multi Protocol BGP" },
    { value: "1176", label: "Network Programming" },
    { value: "1178", label: "Network Engineering" },
    { value: "1179", label: "Computer Vision" },
    { value: "1181", label: "RethinkDB" },
    { value: "1183", label: "CI/CD" },
    { value: "1184", label: "SPARQL" },
    { value: "1185", label: "Application Programming" },
    { value: "1186", label: "Google AdWords" },
    { value: "1187", label: "Enterprise Architecture" },
    { value: "1188", label: "PEAR" },
    { value: "1189", label: "Axure RP" },
    { value: "1190", label: "Software Licensing" },
    { value: "1191", label: "OCR Tesseract" },
    { value: "1192", label: "SAPUI5" },
    { value: "1193", label: "Storybook" },
    { value: "1194", label: "Raspberry Pi" },
    { value: "1197", label: "Customer Development" },
    { value: "1199", label: "Product Roadmap" },
    { value: "1201", label: "Docker Swarm Mode" },
    { value: "1202", label: "Functional Testing" },
    { value: "1203", label: "Perl Mojolicious" },
    { value: "1204", label: "Perl Catalyst" },
    { value: "1205", label: "Selenium WebDriver" },
    { value: "1206", label: "AWS CodeDeploy" },
    { value: "1207", label: "Information Security" },
    { value: "1208", label: "Facebook Development" },
    { value: "1209", label: "Telegram API" },
    { value: "1210", label: "Slack" },
    { value: "1211", label: "Skype Development" },
    { value: "1212", label: "Dialogflow API" },
    { value: "1213", label: "ManyChat" },
    { value: "1214", label: "Natural Language Processing" },
    { value: "1215", label: "Wit.ai Development" },
    { value: "1217", label: "IBM Watson" },
    { value: "1219", label: "Plugin Development" },
    { value: "1220", label: "Article Rewriting" },
    { value: "1200", label: "Website Wireframing" },
    { value: "1216", label: "Startup Consulting" },
    { value: "1142", label: "UI/UX Prototyping" },
    { value: "1221", label: "Translation English Polish" },
    { value: "1222", label: "Translation Polish English" },
    { value: "1223", label: "QA" },
    { value: "1224", label: "Puppet" },
    { value: "1225", label: "Email Design" },
    { value: "1226", label: "Photo Retouching" },
    { value: "1228", label: "VBA" },
    { value: "1230", label: "NetSuite Development" },
    { value: "1232", label: "Motion Design" },
    { value: "1233", label: "Application Server" },
    { value: "1234", label: "Critical Thinking" },
    { value: "1235", label: "Music" },
    { value: "1236", label: "Art History" },
    { value: "1237", label: "Corporate Social Media" },
    { value: "1239", label: "Flash" },
    { value: "1240", label: "People Management" },
    { value: "1241", label: "Varnish" },
    { value: "1243", label: "Management Consulting" },
    { value: "1244", label: "Project Portfolio Management" },
    { value: "1245", label: "Lean Management" },
    { value: "1247", label: "Consulting" },
    { value: "1248", label: "Outsourcing" },
    { value: "1249", label: "Business Transformation" },
    { value: "1250", label: "Innovation Management" },
    { value: "1251", label: "Executive Management" },
    { value: "1252", label: "People Development" },
    { value: "1253", label: "Lean Thinking" },
    { value: "1254", label: "Lean Startup" },
    { value: "1255", label: "Innovation Consulting" },
    { value: "1256", label: "Business Innovation" },
    { value: "1257", label: "Building New Business" },
    { value: "1260", label: "Creative Entrepreneurship" },
    { value: "1264", label: "Startup Marketing" },
    { value: "1265", label: "Incubation" },
    { value: "1266", label: "Lean Canvas" },
    { value: "1267", label: "Business to consumer sales (B2C)" },
    { value: "1268", label: "Offline marketing" },
    { value: "1269", label: "Web Content" },
    { value: "1270", label: "Content Strategy" },
    { value: "1271", label: "Online Journalism" },
    { value: "1273", label: "Content Management" },
    { value: "1274", label: "Editorial" },
    { value: "1275", label: "Security" },
    { value: "1276", label: "Cyber-security" },
    { value: "1277", label: "Web Content Writing" },
    { value: "1278", label: "Newsletters" },
    { value: "1279", label: "VOIP Administration" },
    { value: "1280", label: "Resume Writing" },
    { value: "1281", label: "Content Writing" },
    { value: "1282", label: "Voice Over" },
    { value: "1283", label: "Voice Acting" },
    { value: "1284", label: "Report Writing" },
    { value: "1285", label: "Article Writing" },
    { value: "1286", label: "Publishing" },
    { value: "1287", label: "Social Entrepreneurship" },
    { value: "1290", label: "SMO" },
    { value: "1291", label: "Web Analytics" },
    { value: "1293", label: "Newspapers" },
    { value: "1294", label: "Speech Writing" },
    { value: "1295", label: "Magazines" },
    { value: "1296", label: "Crisis Communications" },
    { value: "1297", label: "Legal Assistance" },
    { value: "1298", label: "Audio Typing" },
    { value: "1299", label: "Project Delivery" },
    { value: "1300", label: "Performance Management" },
    { value: "1301", label: "Customer Experience" },
    { value: "1302", label: "Quality Assurance" },
    { value: "1303", label: "Online Publishing" },
    { value: "1304", label: "Business Process Improvement" },
    { value: "1305", label: "Change Management" },
    { value: "1306", label: "Staff Development" },
    { value: "1307", label: "Intranet" },
    { value: "1308", label: "Web Content Management" },
    { value: "1310", label: "PR" },
    { value: "1311", label: "Ghostwriting" },
    { value: "1312", label: "Presenter" },
    { value: "1229", label: "Python-Goose" },
    { value: "1246", label: "Start-ups" },
    { value: "1272", label: "SEO Copywriting" },
    { value: "1262", label: "Startup Development" },
    { value: "1263", label: "Start-ups Management" },
    { value: "1314", label: "Feature Articles" },
    { value: "1315", label: "Ghostwriter" },
    { value: "1316", label: "Outsourcing Management" },
    { value: "1317", label: "Organization" },
    { value: "1318", label: "Content Development" },
    { value: "1319", label: "Customs Regulations" },
    { value: "1320", label: "Shipping" },
    { value: "1321", label: "International Logistics" },
    { value: "1322", label: "Ocean" },
    { value: "1323", label: "Logistics" },
    { value: "1324", label: "Supply Chain" },
    { value: "1326", label: "International Shipping" },
    { value: "1327", label: "Supply Chain Management" },
    { value: "1328", label: "Import Export" },
    { value: "1329", label: "Customs" },
    { value: "1330", label: "International Trade" },
    { value: "1331", label: "Operations Management" },
    { value: "1332", label: "International Business" },
    { value: "1333", label: "Transportation" },
    { value: "1334", label: "Air Freight" },
    { value: "1335", label: "Logistics Management" },
    { value: "1338", label: "Import" },
    { value: "1340", label: "Marketing Copy" },
    { value: "1342", label: "User Experience" },
    { value: "1343", label: "Technical Writing" },
    { value: "1344", label: "Usability" },
    { value: "1346", label: "User-centered Design" },
    { value: "1349", label: "Information Architecture" },
    { value: "1350", label: "Identity Management" },
    { value: "1351", label: "User Interface" },
    { value: "1353", label: "Manual Testing" },
    { value: "1354", label: "Integration" },
    { value: "1355", label: "User Research" },
    { value: "1357", label: "Technical Communication" },
    { value: "1358", label: "Information Design" },
    { value: "1359", label: "Usability Engineering" },
    { value: "1360", label: "Contextual Inquiry" },
    { value: "1361", label: "Persona" },
    { value: "1362", label: "Style Guides" },
    { value: "1363", label: "Online Help" },
    { value: "1364", label: "Medical Writing" },
    { value: "1365", label: "Medical Education" },
    { value: "1366", label: "Composition" },
    { value: "1368", label: "Songwriting" },
    { value: "1370", label: "Assistenza clienti" },
    { value: "1371", label: "Ricerca" },
    { value: "1372", label: "Vendite" },
    { value: "1373", label: "Insurance" },
    { value: "1374", label: "Liability" },
    { value: "1375", label: "Brokers" },
    { value: "1376", label: "Commercial Insurance" },
    { value: "1377", label: "Property & Casualty Insurance" },
    { value: "1378", label: "Enterprise Risk Management" },
    { value: "1379", label: "Casualty" },
    { value: "1380", label: "Claims Management" },
    { value: "1381", label: "General Insurance" },
    { value: "1382", label: "Underwriting" },
    { value: "1383", label: "Legal Liability" },
    { value: "1384", label: "Reinsurance" },
    { value: "1385", label: "Professional Liability" },
    { value: "1386", label: "Business" },
    { value: "1387", label: "Workers Compensation" },
    { value: "1388", label: "Risk Analysis" },
    { value: "1389", label: "Claim" },
    { value: "1390", label: "Commercial Lines" },
    { value: "1391", label: "Data Research" },
    { value: "1392", label: "Call Centers" },
    { value: "1393", label: "Telephone Skills" },
    { value: "1394", label: "Forecasting" },
    { value: "1395", label: "SAP" },
    { value: "1396", label: "Cross-functional Team Leadership" },
    { value: "1397", label: "Process Improvement" },
    { value: "1400", label: "Talent Acquisition" },
    { value: "1401", label: "Managed Services" },
    { value: "1402", label: "MPLS" },
    { value: "1403", label: "VoIP" },
    { value: "1404", label: "WAN" },
    { value: "1405", label: "Direct Sales" },
    { value: "1406", label: "Solution Selling" },
    { value: "1407", label: "Ethernet" },
    { value: "1348", label: "Software Project Management" },
    { value: "1409", label: "SEM" },
    { value: "1410", label: "Display Advertising" },
    { value: "1411", label: "Mobile Marketing" },
    { value: "1412", label: "Social Media Optimization (SMO)" },
    { value: "1413", label: "Table Tennis" },
    { value: "1414", label: "Strength Training" },
    { value: "1415", label: "Sports" },
    { value: "1416", label: "Personal Training" },
    { value: "1417", label: "Private Equity" },
    { value: "1418", label: "Vendor Management" },
    { value: "1419", label: "Nutrition" },
    { value: "1420", label: "Creativity Skills" },
    { value: "1421", label: "Innovation" },
    { value: "1423", label: "Social Media Measurement" },
    { value: "1425", label: "Campaign Management" },
    { value: "1428", label: "Managing suppliers" },
    { value: "1430", label: "Search Advertising" },
    { value: "1431", label: "Conversion Optimization" },
    { value: "1432", label: "Digital Strategy" },
    { value: "1433", label: "Organic Search" },
    { value: "1434", label: "Yahoo Search Marketing" },
    { value: "1436", label: "Budgetary Management" },
    { value: "1438", label: "Investments" },
    { value: "1440", label: "Acting" },
    { value: "1441", label: "business support" },
    { value: "1442", label: "Retargeting" },
    { value: "1444", label: "Trade Marketing" },
    { value: "1445", label: "HTML emails" },
    { value: "1446", label: "HTML + CSS" },
    { value: "1447", label: "International Relations" },
    { value: "1449", label: "Procurement" },
    { value: "1451", label: "Venture Capital" },
    { value: "1452", label: "Mergers & Acquisitions" },
    { value: "1453", label: "Strategic Partnerships" },
    { value: "1454", label: "Leadership Development" },
    { value: "1455", label: "Executive Coaching" },
    { value: "1456", label: "Governance" },
    { value: "1457", label: "Asset Managment" },
    { value: "1458", label: "Financial Services" },
    { value: "1460", label: "Data Storytelling" },
    { value: "1461", label: "Commercial Strategy" },
    { value: "1462", label: "Psychometrics" },
    { value: "1463", label: "Stage Combat" },
    { value: "1464", label: "Database Management System" },
    { value: "1466", label: "Full-stack development" },
    { value: "1467", label: "Front-end automation" },
    { value: "1468", label: "Monitoring" },
    { value: "1469", label: "Technical Presentations" },
    { value: "1470", label: "Workshops" },
    { value: "1471", label: "Interview Skills" },
    { value: "1472", label: "Web Performance" },
    { value: "1473", label: "Financial Markets" },
    { value: "1474", label: "Financial Modeling" },
    { value: "1475", label: "Strategy Implementation" },
    { value: "1476", label: "Regulatory Analysis" },
    { value: "1477", label: "Relationship Management" },
    { value: "1478", label: "System Center Configuration Manager (SCCM)" },
    { value: "1479", label: "Powershell" },
    { value: "1480", label: "Microsoft Deployment Toolkit (MDT)" },
    { value: "1481", label: "Software Deployment" },
    { value: "1482", label: "Mathematics" },
    { value: "1484", label: "Compliance PCI" },
    { value: "1485", label: "CAD" },
    { value: "1486", label: "Materials" },
    { value: "1487", label: "Sheet Metal" },
    { value: "1488", label: "Manufacturing" },
    { value: "1489", label: "Steel" },
    { value: "1490", label: "CAD/CAM" },
    { value: "1492", label: "Continuous Improvement" },
    { value: "1493", label: "Computer-Aided Design (CAD)" },
    { value: "1494", label: "Geometric Dimensioning" },
    { value: "1495", label: "Inventor" },
    { value: "1496", label: "Autodesk Inventor" },
    { value: "1497", label: "VB6" },
    { value: "1498", label: "Microsoft Access" },
    { value: "1499", label: "Dreamweaver" },
    { value: "1439", label: "Cold Calling" },
    { value: "1491", label: "Technical Drawing" },
    { value: "1443", label: "B2B Marketing" },
    { value: "1424", label: "Direct Marketing" },
    { value: "1500", label: "Crystal Reports" },
    { value: "1501", label: "SigmaNest" },
    { value: "1502", label: "Mechanical Drawings" },
    { value: "1503", label: "Mechanical Desktop" },
    { value: "1504", label: "Laser Cutting" },
    { value: "1505", label: "Autodesk Software" },
    { value: "1506", label: "3rd Line Support" },
    { value: "1507", label: "VB4" },
    { value: "1510", label: "Algorithms" },
    { value: "1511", label: "Transact-SQL (T-SQL)" },
    { value: "1512", label: "Unified Modeling Language (UML)" },
    { value: "1513", label: "Microsoft Azure" },
    { value: "1515", label: "WCF" },
    { value: "1516", label: "T-SQL" },
    { value: "1517", label: "Windows Azure" },
    { value: "1518", label: "Visual C#" },
    { value: "1519", label: "WPF" },
    { value: "1520", label: "TFS" },
    { value: "1521", label: "SQL Server" },
    { value: "1522", label: "Knockout JS" },
    { value: "1523", label: "Single Page Applications" },
    { value: "1524", label: "Kendo UI" },
    { value: "1525", label: "SignalR" },
    { value: "1526", label: "Windows Identity Framework" },
    { value: "1527", label: "Visual Basic .NET (VB.NET)" },
    { value: "1528", label: "WinForms" },
    { value: "1529", label: "SQL Server Management Studio" },
    { value: "1531", label: "Kendo" },
    { value: "1532", label: "Telerik Web Controls" },
    { value: "1533", label: "Telerik Controls" },
    { value: "1534", label: "Windows Services" },
    { value: "1535", label: "Remote Troubleshooting" },
    { value: "1536", label: "Virtual Servers" },
    { value: "1537", label: "Kendo UI Controls" },
    { value: "1538", label: "ERP" },
    { value: "1539", label: "Software Design" },
    { value: "1540", label: "Software Installation" },
    { value: "1541", label: "Infrastructure" },
    { value: "1542", label: "VBScript" },
    { value: "1545", label: "Creative Solutions Provider" },
    { value: "1546", label: "Entity Framework" },
    { value: "1547", label: "ZURB Foundation Framework" },
    { value: "1548", label: "Kentico" },
    { value: "1550", label: "Software Integration" },
    { value: "1551", label: "XAML" },
    { value: "1552", label: "ASP.NET AJAX" },
    { value: "1554", label: "Telerik" },
    { value: "1557", label: "AJAX Toolkit" },
    { value: "1558", label: "Tortoise SVN" },
    { value: "1559", label: "NopCommerce cms" },
    { value: "1560", label: "Windows Communication Foundation (WCF)" },
    { value: "1561", label: "Linux Server" },
    { value: "1562", label: "Simfony" },
    { value: "1563", label: "Wekan" },
    { value: "1564", label: "CorelDRAW" },
    { value: "1565", label: "JavaServer Pages (JSP)" },
    { value: "1566", label: "Maven" },
    { value: "1567", label: "Spring MVC" },
    { value: "1568", label: "Java Enterprise Edition" },
    { value: "1569", label: "Java Database Connectivity (JDBC)" },
    { value: "1570", label: "JPA" },
    { value: "1571", label: "Microsoft Visual Studio Code" },
    { value: "1572", label: "IntelliJ IDEA" },
    { value: "1573", label: "Oracle SQL Developer" },
    { value: "1574", label: "Liquibase" },
    { value: "1575", label: "Gradle" },
    { value: "1576", label: "Spring REST" },
    { value: "1577", label: "JS" },
    { value: "1578", label: "Realm" },
    { value: "1579", label: "RxSwift" },
    { value: "1580", label: "Redmine" },
    { value: "1582", label: "MVVMc" },
    { value: "1583", label: "Vanila JS" },
    { value: "1584", label: "IT Service Management" },
    { value: "1586", label: "Web-based Research" },
    { value: "1588", label: "Zendesk" },
    { value: "1590", label: "Swift (Programming Language)" },
    { value: "1592", label: "CocoaPods" },
    { value: "1593", label: "Sourcetree" },
    { value: "1594", label: "iOS Design" },
    { value: "1595", label: "Vehicle Tracking" },
    { value: "1589", label: "Dart" },
    { value: "1596", label: "App Store Optimization" },
    { value: "1597", label: "Interpersonal Communication" },
    { value: "1598", label: "Personal Development" },
    { value: "1599", label: "Entomology" },
    { value: "1600", label: "Google Cloud Platform (GCP)" },
    { value: "1602", label: "Content Management Systems (CMS)" },
    { value: "1604", label: "InVision" },
    { value: "1606", label: "Testing" },
    { value: "1607", label: "Microsoft Outlook" },
    { value: "1608", label: "SAP FI" },
    { value: "1609", label: "Invoicing" },
    { value: "1610", label: "Investment Banking" },
    { value: "1611", label: "Commercial Banking" },
    { value: "1612", label: "SAP CO" },
    { value: "1613", label: "Team Motivation" },
    { value: "1614", label: "Optical Character Recognition (OCR)" },
    { value: "1615", label: "KPI Reports" },
    { value: "1616", label: "socket.io" },
    { value: "1617", label: "VHDL" },
    { value: "1618", label: "LaTeX" },
    { value: "1619", label: "Open Source Software" },
    { value: "1620", label: "PDO" },
    { value: "1621", label: "PgSQL" },
    { value: "1622", label: "Antivirus" },
    { value: "1625", label: "Unix Services" },
    { value: "1626", label: "Server Architecture" },
    { value: "1627", label: "Phalcon Framework" },
    { value: "1628", label: "Silverlight" },
    { value: "1629", label: "SSRS" },
    { value: "1631", label: "MS .Net" },
    { value: "1632", label: "Language Integrated Query (LINQ)" },
    { value: "1633", label: "Team Foundation Server (TFS)" },
    { value: "1634", label: "AJAX Frameworks" },
    { value: "1635", label: "IoC" },
    { value: "1636", label: "Life Insurance" },
    { value: "1637", label: "Debugging" },
    { value: "1638", label: "Business Requirements" },
    { value: "1640", label: "Data Warehousing" },
    { value: "1641", label: "Extract, Transform, Load (ETL)" },
    { value: "1642", label: "Microsoft Products" },
    { value: "1643", label: "Informatica" },
    { value: "1644", label: "Microsoft Project" },
    { value: "1645", label: "Office 365" },
    { value: "1646", label: "SharePoint" },
    { value: "1647", label: "Direct Client Interaction" },
    { value: "1648", label: "Use Case" },
    { value: "1649", label: "Reporting" },
    { value: "1650", label: "Nintex" },
    { value: "1651", label: "Kofax" },
    { value: "1653", label: "App Store" },
    { value: "1654", label: "Resource Management" },
    { value: "1655", label: "Key Performance Indicators" },
    { value: "1656", label: "Mentoring" },
    { value: "1657", label: "Design Thinking" },
    { value: "1658", label: "Servant Leadership" },
    { value: "1660", label: "Scope Management" },
    { value: "1661", label: "Leading Meetings" },
    { value: "1662", label: "Project Management Office (PMO)" },
    { value: "1663", label: "Contract Management" },
    { value: "1664", label: "Service Delivery" },
    { value: "1665", label: "IT Governance" },
    { value: "1666", label: "Project Governance" },
    { value: "1667", label: "Process Implementation" },
    { value: "1668", label: "PMO Development" },
    { value: "1669", label: "PMO set-up" },
    { value: "1670", label: "B2B sales" },
    { value: "1671", label: "Information Technology" },
    { value: "1672", label: "Webmaster Services" },
    { value: "1673", label: "IT and Communications Lead Generation" },
    { value: "1674", label: "IT Hardware resale and procurement" },
    { value: "1675", label: "Database Marketing" },
    { value: "1676", label: "Office Management" },
    { value: "1677", label: "Salesforce.com" },
    { value: "1678", label: "Conference Production" },
    { value: "1679", label: "Technology Recruitment" },
    { value: "1680", label: "Exhibit Design" },
    { value: "1681", label: "Exhibit Preparation" },
    { value: "1682", label: "Salesforce Training" },
    { value: "1683", label: "Recruiting" },
    { value: "1684", label: "R (language)" },
    { value: "1685", label: "IT recruiting" },
    { value: "1624", label: "C/C++" },
    { value: "1694", label: "Phoenix" },
    { value: "1698", label: "Groovy" },
    { value: "1700", label: "Arduino" },
    { value: "1693", label: "Elixir" },
    { value: "1695", label: "Gatsby" },
    { value: "1347", label: "Technical Documentation" },
    { value: "1345", label: "Requirements Analysis" },
    { value: "193", label: "Marketing Automation" },
    { value: "1708", label: "Strapi" },
    { value: "306", label: "TypeScript" },
    { value: "2", label: "Google Tag Manager" },
    { value: "144", label: "Vue.js" },
    { value: "7", label: "jQuery" },
    { value: "170", label: "React Native" },
    { value: "17", label: "JavaScript" },
    { value: "1", label: "Google Analytics" },
    { value: "1652", label: "Business Systems Analysis" },
    { value: "1639", label: "Project Coordination" },
    { value: "1687", label: "Directo" },
    { value: "1705", label: "No-code Development" },
    { value: "1703", label: "Tailwind CSS" },
    { value: "1704", label: "N8N" },
    { value: "1702", label: "Cypress" },
    { value: "1697", label: "Java virtual machine (JVM)" },
    { value: "1701", label: "Beautiful Soup" },
    { value: "1689", label: "Booload Search" },
    { value: "1699", label: "Haxe" },
    { value: "1690", label: "Candidate Sourcing" },
    { value: "1692", label: "Postman" },
    { value: "1696", label: "FPGA" },
  ];

  let skills = [
    { skill: "", scale: "", started_at: "", comment: "" },
    { skill: "", scale: "", started_at: "", comment: "" },
  ];

  const addField = () => {
    skills = [...skills, { skill: "", scale: "", started_at: "", comment: "" }];
    skills = [...skills, { skill: "", scale: "", started_at: "", comment: "" }];  
    const skillId = skills.length - 1;
    const htmlId = "skill-" + skillId;
    const element = document.getElementById(htmlId);
  };

  function handleSelect(event) {
    skillType = event.detail;
  }

  function handleClear() {
    skillType = undefined;
  }

  function submitSkills() {
    data = '{"objects": [';
    for (let i = 0; i < skills.length; i++) {
      if (skills[i].started_at) {
        var d = new Date();
        d.setMonth(d.getMonth() - skills[i].started_at);
        started_at = '"' + d.toISOString().split("T")[0] + '"'; // format to YYYY-MM-DD and wrap with  ""
      } else started_at = null;
      if (skills[i].scale) scale = '"' + skills[i].scale + '"';
      else scale = null;

      if (i > 0) data += ",";
      data +=
        '{ "skill_id": ' +
        skills[i].skill.value +
        ', "user_id": ' +
        cookie[0] +
        ', "scale": ' +
        scale +
        ', "started_at": ' +
        started_at +
        ', "comment": "' +
        skills[i].comment +
        '", "main_skill": false }';
    }
    data += "]}";

    fetch("https://apps.crewnew.com/api/rest/apply/step2", {
      method: "POST",
      body: data,
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "x-hasura-admin-secret": "Yd3L3tExefP5AUyP",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("An error occurred, please try again!");
        }
        return res.json();
      })
      .then((data) => {
        window.location.href = "#/step3";
        dispatch("step3");
      })
      .catch((err) => {
        console.log(err);
      });
  }
</script>

<svelte:head>
  <title>🔧 what's in your toolbox? | Join CrewNew</title>
</svelte:head>

<Modal
  title="Great, {cookie[1]}! Now the most important part 🔧🔧🔧"
  on:cancel
  wide="true"
  closable="no"
>
  <p>
    Tell us about your top <strong>at least 3-4</strong> skills that you're
    <strong>best at</strong> and you'd like to wok on. But the more and the more
    detailed information you provide, the better projects/tasks we'll assign to you
    💪
  </p>
  <section id="apply">
    {#each skills as v, i}
      <article>
        <h4 id="skill-{i}">Skill #{i + 1}</h4>
        <div>
          <div class="themed">
            <Select
              id={i}
              {items}
              placeholder="Search skill... *"
              on:select={handleSelect}
              on:clear={handleClear}
              bind:value={skills[i].skill}
            />
          </div>
        </div>
        <div class="form-control">
          <label for="scale">Seniority <span class="required">*</span></label>
          <select
            class="minimal"
            name="scale"
            id={i}
            bind:value={skills[i].scale}
          >
            <option value="1">1 - Plan</option>
            <option value="2">2 - Started</option>
            <option value="3">3 - Learning</option>
            <option value="4">4 - Junior</option>
            <option value="5">5 - Junior/Mid</option>
            <option value="6">6 - Midlevel</option>
            <option value="7">7 - Mid/Senior</option>
            <option value="8">8 - Senior</option>
            <option value="9">9 - Lead</option>
            <option value="10">10 - Evangelist</option>
          </select>
        </div>
        <div class="form-control">
          <label for="started_at">Professional experience <span class="required">*</span></label>
          <select
            class="minimal"
            name="scale"
            id={i}
            bind:value={skills[i].started_at}
          >
            <option value="6">6 months</option>
            <option value="12">1 year</option>
            <option value="18">1.5 years</option>
            <option value="24">2 years</option>
            <option value="36">3 years</option>
            <option value="48">4 years</option>
            <option value="60">5 years</option>
            <option value="90">7-8 years</option>
            <option value="115">9-10 years</option>
            <option value="155">11-15 years</option>
            <option value="210">16-19 years</option>
            <option value="265">20-24 years</option>
            <option value="325">25-29 years</option>
            <option value="375">30+ years</option>
          </select>
        </div>
        <div class="form-control">
          <label for="comment">Tell the story </label>
          <textarea
            name="comment"
            id={i}
            bind:value={skills[i].comment}
            placeholder="Why this? History?"
          />
        </div>
      </article>
    {/each}
  </section>
  <div class="space" id="cn-bottom">
    <Button mode="outline" on:click={addField}>Add more skills 😍</Button>
  </div>
  <div class="space">
    <Button on:click={submitSkills}>Save your skills 👊</Button>
  </div>
  <pre>
  <!-- {JSON.stringify(skills, null, 2)} -->
 </pre>
</Modal>

<style>
  .required {
    color:rgb(154, 31, 31);
    font-weight: 500;
    font-size: larger;
  }
  .space {
    margin-top: 1rem;
    text-align: center;
  }
  .form-control {
    display: grid;
    grid-template-columns: 10rem 10rem;
    padding: 0.5rem 0;
    margin: 0.25rem 0;
  }
  .themed {
    --border: 2px solid rgb(218, 216, 216);
    --borderFocusColor: #42375b;
    --borderRadius: 1px;
    --height: 38px;
    --placeholderColor: rgb(155, 155, 159);
    --indicatorWidth: 1rem;
  }

  select,
  textarea {
    /* styling */
    background-color: white;
    border: 2px solid rgb(218, 216, 216);
    width: 19rem;
    border-radius: 1px;
    font: inherit;
    line-height: 1em;
    padding: 0.5em 3.5em 0.5em 1em;

    /* reset */

    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  /* arrows */

  select.minimal {
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
  }

  select.minimal:focus {
    background-image: linear-gradient(45deg, #42375b 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, #42375b 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
      calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    border-color: #42375b;
    outline: 0;
  }

  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
  textarea::placeholder {
    font-weight: bold;
    opacity: 0.5;
    color: rgb(189, 184, 184);
  }

  article {
    background: white;
    margin: 1rem;
  }
  section {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }

  @media (min-width: 768px) {
    section {
      grid-template-columns: repeat(2, 1fr);
    }
  }
</style>

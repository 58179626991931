<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  import Select from "svelte-select";
  let instantMessengerType = "";

  //import messengers from "./messengers-store";

  import { onMount } from "svelte";
  let messengersCMS = [];
  onMount(() => {
    fetch("https://apps.crewnew.com/api/rest/cms/messengers")
      .then((response) => response.json())
      .then((result) => {
        messengersCMS = result.cms_messengers;
      });
  });

  const items = [
    {
      value: "whatsapp",
      label:
        "<img src='https://projects.crewnew.com/crewnew/img/icons/wa.png' width='12' alt='WhatsApp'> WhatsApp",
    },
    {
      value: "discord",
      label:
        "<img src='https://projects.crewnew.com/crewnew/img/icons/discord.png' alt='Discord'> Discord",
    },
    {
      value: "facebook",
      label:
        "<img src='https://projects.crewnew.com/crewnew/img/icons/fb.png' alt='Messenger'> Facebook Messenger",
    },
    {
      value: "skype",
      label:
        "<img src='https://projects.crewnew.com/crewnew/img/icons/skype.png' alt='Skype'> Skype",
    },
    {
      value: "telegram",
      label:
        "<img src='https://projects.crewnew.com/crewnew/img/icons/telegram.png' alt='Telegram'> Telegram",
    },
    {
      value: "viber",
      label:
        "<img src='https://projects.crewnew.com/crewnew/img/icons/viber.png' width='12' alt='Viber'> Viber",
    },
    {
      value: "instagram",
      label:
        "<img src='https://projects.crewnew.com/crewnew/img/icons/instagram.png' alt='Instagram'> Instagram Messenger",
    },
  ];

  function handleSelect(event) {
    instantMessengerType = event.detail;
    dispatch("messenger", instantMessengerType);
  }
</script>

<div class="themed">
  <Select {items} on:select={handleSelect} />
</div>

<style>
  .themed {
    --border: 2px solid rgb(218, 216, 216);
    --borderRadius: 1px;
    --height: 30px;
    --placeholderColor: rgb(155, 155, 159);
  }
</style>
